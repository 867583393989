import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { format } from 'date-fns-tz'
import { textFilter } from "react-bootstrap-table2-filter";
import config from '~/config';
import fetch from '~/redux/fetch';
import {Modal} from "react-bootstrap";
import JSONPretty from "react-json-pretty";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import cs from "classnames";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {Tooltip} from "@material-ui/core";
import FlagImage from '~/app/helpers/flagsSVGs';
import enums from "~/app/helpers/enums";

const MinusIcon = ({ className = '' }) => (
  <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Stockholm-icons / Code / Minus</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Code-/-Minus" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="bound" x="0" y="0" width="24" height="24"></rect>
      <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
      <rect id="Rectangle" fill="#000000" x="6" y="11" width="12" height="2" rx="1"></rect>
    </g>
  </svg>
);

const PlusIcon = ({ className = '' }) =>  (
  <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Stockholm-icons / Code / Plus</title>
    <desc>Created with Sketch.</desc>
    <defs></defs>
    <g id="Stockholm-icons-/-Code-/-Plus" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="bound" x="0" y="0" width="24" height="24"></rect>
      <circle id="Oval-5" fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
      <path d="M11,11 L11,7 C11,6.44771525 11.4477153,6 12,6 C12.5522847,6 13,6.44771525 13,7 L13,11 L17,11 C17.5522847,11 18,11.4477153 18,12 C18,12.5522847 17.5522847,13 17,13 L13,13 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4477153,18 11,17.5522847 11,17 L11,13 L7,13 C6.44771525,13 6,12.5522847 6,12 C6,11.4477153 6.44771525,11 7,11 L11,11 Z" id="Combined-Shape" fill="#000000"></path>
    </g>
  </svg>
);

export const decisionCodeToDisplay = (status) => {
  if (!status) { return ''; }

  let result = _.capitalize(status);

  if (status === 'created') { // veriff session created
    result = 'Generated';
  }

  if (result === 'Approved') {
    result = <span style={{ color: 'green' }}>{result}</span>;
  } else if (result === 'Declined') {
    result = <span style={{ color: 'red' }}>{result}</span>;
  }

  return result;
};

export const onTableChangeHelper = ({
  actions,
  dispatch,
  pageNumber, pageSize,
  sortBy, sortDirection,
  filters, allFilterFields, numberFilterFields,
  didMount,
}) => (
  (type, { page, sizePerPage, sortField, sortOrder, filters: tableFilters}) => {
    if (didMount) {
      switch (type) {
        case 'pagination':
          const newPage = page || 1;
          if (newPage !== pageNumber || sizePerPage !== pageSize) {
            dispatch(actions.setPagination({ pageNumber: newPage, pageSize: sizePerPage }));
          }
          break;

        case 'sort':
          if (sortField !== sortBy || sortOrder !== sortDirection) {
            dispatch(actions.setSorting({ sortBy: sortField, sortDirection: sortOrder }));
          }
          break;

        case 'filter':
          allFilterFields.forEach(field => {
            let newFilter = _.get(tableFilters[field], 'filterVal', null);
            // numbers become strings somewhere inside bootstrap-table
            if (numberFilterFields?.includes?.(field) && newFilter) { newFilter = +newFilter; }
            if (filters[field] !== newFilter) {
              dispatch(actions.setFilter(field, newFilter));
            }
          });
          break;

        default:
          break;
      }
    }
  }
);

export const useDidMount = () => {
  const mountRef = useRef(false);
  useEffect(() => { mountRef.current = true }, []);
  return mountRef.current;
}

export const usePaginationFilteringAndSorting = (dispatch, storeRoot, actions) => {
  const sortBy = useSelector(state => state[storeRoot].sortBy);
  const sortDirection = useSelector(state => state[storeRoot].sortDirection);
  const pageNumber = useSelector(state => state[storeRoot].pageNumber);
  const pageSize = useSelector(state => state[storeRoot].pageSize);
  const filters = useSelector(state => state[storeRoot].filters);
  const totalCount = useSelector(state => state[storeRoot].totalCount);

  const didMount = useDidMount();

  useEffect(() => {
    if (didMount) {
      dispatch(actions.updateReport());
    }
  }, [pageNumber, pageSize,sortBy, sortDirection, filters, dispatch, didMount, actions]);

  return {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  };
};

export const actionsForPaginatedSortedFilteredFetchableReport = (moduleId) => {
  const actionTypes = {
    SetStartDate: `${moduleId}/set-start-date`,
    SetEndDate: `${moduleId}/set-end-date`,

    SetPagination: `${moduleId}/set-pagination`,
    SetSorting: `${moduleId}/set-sorting`,
    SetFilter: `${moduleId}/set-filter`,

    UpdateReport: `${moduleId}/update-report`,
    GenerateReport: `${moduleId}/generate-report`,
    FetchReportSuccess: `${moduleId}/fetch-report-success`,
    FetchReportError: `${moduleId}/fetch-report-failure`,
    SetUseCountryFieldSort: `${moduleId}/set-use-country-field-sort`,
    SetUseBannedAtSort: `${moduleId}/set-use-banned-at-sort`,
  };

  const actions = {
    setStartDate: (startDate) => ({
      type: actionTypes.SetStartDate,
      payload: startDate
    }),
    setEndDate: (endDate) => ({
      type: actionTypes.SetEndDate,
      payload: endDate
    }),

    setPagination: ({ pageNumber, pageSize }) => ({
      type: actionTypes.SetPagination,
      payload: { pageNumber, pageSize }
    }),
    setSorting: ({ sortBy, sortDirection }) => ({
      type: actionTypes.SetSorting,
      payload: { sortBy, sortDirection }
    }),
    setFilter: (field, value) => ({
      type: actionTypes.SetFilter,
      payload: { field, value }
    }),

    updateReport: () => ({
      type: actionTypes.UpdateReport,
      payload: {}
    }),
    generateReport: () => ({
      type: actionTypes.GenerateReport,
      payload: {}
    }),
    fetchReportSuccess: (results) => ({
      type: actionTypes.FetchReportSuccess,
      payload: results
    }),
    fetchReportFailure: (errorMessage) => ({
      type: actionTypes.FetchReportError,
      payload: errorMessage
    }),

    setUseCountryFieldSort: (value) => ({
      type: actionTypes.SetUseCountryFieldSort,
      payload: value
    }),

    SetUseBannedAtSort: (value) => ({
      type: actionTypes.SetUseBannedAtSort,
      payload: value
    }),

  }

  return [actionTypes, actions];
};

// needs .tableheighthack class set on the table to work
// (class only contains "height: 1px;" rule
export const beautifyColumnHeaders = (columns) => {
  return columns.map(col => ({
    ...col,
    headerFormatter: (_column, _colIndex, components) => {
      return (
        <div style={{ display: 'inline-block', width: '100%', height: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div>{col.text} {components.sortElement}</div>
            <div style={{ flex: '1 1' }}></div>
            <div>{components.filterElement}</div>
          </div>
        </div>
      );
    },
  }));
};

export const nth = function(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}

export const markInUserSecurityMarks = (mark) => {
  return ((mark.startsWith('storage') && !enums.fraudMarksExceptions.includes(mark)) || (mark.startsWith('api') &&
    !enums.fraudMarksExceptions.includes(mark)) || enums.fraudUserMarks.includes(mark));
};

export const newDateFormatLong = (date, timezone, withoutSeconds = false) => {
  if(!date) {
    return '-'
  }
  const dateInPSTFormat = new Date(date).toLocaleString("en-US", {timeZone: 'America/Los_Angeles'});
  const newDate = new Date(dateInPSTFormat);
  const newSplitDate  = format(newDate, withoutSeconds ? 'MMM d, yyyy, HH:mm' :"MMM d, yyyy, HH:mm:ss aaaaa'm'").split(',');
  const day = newSplitDate[0].split(' ')[1];
  return <div>{newSplitDate[0]}<sup>{nth(Number(day))}</sup>{`${newSplitDate[1]}, ${newSplitDate[2]}${timezone || ''}`}</div>;
};

export const newDateFormat = (date) => {
  if(!date) { return '-' }

  const dateFormat = 'MMMM d, yyyy';
  const dateInPSTFormat = new Date(date).toLocaleString("en-US", {timeZone: 'America/Los_Angeles'});
  const newDate = new Date(dateInPSTFormat);
  const newSplitDate  = format(newDate, dateFormat).split(',');
  const day = newSplitDate[0].split(' ')[1];
  return <div>{newSplitDate[0]}<sup>{nth(Number(day))}</sup>{newSplitDate[1]}</div>;
};

export const newDateFormatInUTC = (date, dateFormat = 'MMMM d, yyyy') => {
  if(!date) {
    return '-'
  }
  const dateInUTCFormat = new Date(date).toISOString();
  const newDate = new Date(dateInUTCFormat);
  const newSplitDate  = format(newDate, dateFormat).split(',');
  const day = newSplitDate[0].split(' ')[1];
  return <div>{newSplitDate[0]}<sup>{nth(Number(day))}</sup>{newSplitDate[1]}</div>;
};

export const slashesDateFormat = (date) => {
  if(!date) { return '-' }

  const dateFormat = 'dd/MM/yy';
  const dateInPSTFormat = new Date(date).toLocaleString("en-US", {timeZone: 'America/Los_Angeles'});
  const newDate = new Date(dateInPSTFormat);
  return format(newDate, dateFormat);
};

const CustomAutoComplete = ({
  onFilter,
  column,
  textFilterProps,
  actions,
  notRemote,
}) => {
  const [focused, setFocused] = useState(false);
  const [prevFocused, setPrevFocused] = useState(false);
  const [value, setValue] = useState(textFilterProps.defaultValue || '');
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [scrollTop, setScrollTop] = useState(0);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const countryOptionsBlock = useRef(null);
  const filterDom = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (countryOptionsBlock?.current && scrollTop !== prevScrollTop) {
      setScrollTop(prevScrollTop)
      // eslint-disable-next-line no-unused-expressions
      countryOptionsBlock?.current?.scrollTo(0,prevScrollTop);
    }
  }, [prevScrollTop, scrollTop]);

  useEffect(() => {
    if (prevFocused !== focused) {
      setFocused(prevFocused);
      dispatch(actions.setUseCountryFieldSort(!prevFocused));
    }
  }, [actions, dispatch, focused, prevFocused]);

  const handleClickOnItem = (item) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(item.name);
  };

  const filteredCountryOptions = enums.countriesWithCode.filter((item) => (
    item.name.toLowerCase().startsWith(value.toLowerCase()) ||
    item.code.toLowerCase().includes(value.toLowerCase())
  ));

  const handleOnClick = (e) => {
    if (focused) {
      e.stopPropagation();
      e.preventDefault();
      if (e.target.classList?.value?.includes('countryItem')) {
        setValue(e.target.id);
        if (notRemote) {
          const selectedCode = filteredCountryOptions?.find((item) => (item.name === e.target.id))?.code?.toUpperCase() || value;
          typeof onFilter === 'function' && onFilter(selectedCode);
          setPrevFocused(false);
        } else {
          typeof onFilter === 'function' && onFilter(e.target.id);
          setPrevFocused(false);
        }
      } else {
        setValue(value);
        if (notRemote) {
          const selectedCode = filteredCountryOptions?.find((item) => (item.name === value))?.code?.toUpperCase() || value;
          typeof onFilter === 'function' && onFilter(selectedCode);
        } else {
          typeof onFilter === 'function' && onFilter(value);
        }
        setPrevFocused(false);
      }
    }
  }

  const onBlur = (e) => {
    if (!e.relatedTarget) {
      setPrevFocused(false);
      if (notRemote) {
        const selectedCode = filteredCountryOptions?.find((item) => (item.name === value))?.code?.toUpperCase() || value;
        onFilter(selectedCode);
      } else {
        onFilter(value);
      }
    }
  }

  const handleClickAway = (e) => {
    if (e.path?.[0]?.id !== `text-filter-column-${column.dataField}` && focused) {
      setPrevFocused(false);
      if (notRemote) {
        const selectedCode = filteredCountryOptions?.find((item) => (item.name === value))?.code?.toUpperCase() || value;
        onFilter(selectedCode);
      } else {
        onFilter(value);
      }
    }
  };

  const filter = textFilter(
    {
      defaultValue: value,
      placeholder: ' ',
      delay: 1000000,
      onKeyUp: (e) => {
        if (e.target?.value?.length !== value?.length) {
          if (filteredCountryOptions?.length) {
            setSelectedItem(0);
            setPrevScrollTop(0);
          } else {
            setSelectedItem(-1);
          }
        }
        setValue(e.target?.value);
        if (e.keyCode === 40) {
          e.stopPropagation();
          e.preventDefault();
          if (selectedItem !== -1 && filteredCountryOptions?.length > selectedItem + 1) {
            setSelectedItem(selectedItem + 1);
            const optionHeight = 40;
            const maxVisibleCountryOptions = 10;
            const scrolledItems = scrollTop / optionHeight;
            if (countryOptionsBlock?.current && !((maxVisibleCountryOptions + scrolledItems) > (selectedItem + 1))) {
              setPrevScrollTop(scrollTop + optionHeight);
            }
          }
        }
        if (e.keyCode === 38) {
          e.stopPropagation();
          e.preventDefault();
          if (selectedItem > 0) {
            setSelectedItem(selectedItem - 1);
            const optionHeight = 40;
            const scrolledItems = scrollTop / optionHeight;
            if (countryOptionsBlock?.current && !((scrolledItems < (selectedItem - 1)))) {
              setPrevScrollTop(scrollTop - optionHeight);
            }
          }
        }
        if (e.keyCode === 13) {
          e.stopPropagation();
          e.preventDefault();
          if (selectedItem > -1 && filteredCountryOptions?.length > selectedItem) {
            setValue(filteredCountryOptions?.[selectedItem]?.name);
            onFilter(filteredCountryOptions?.[selectedItem]?.name);
          } else {
            onFilter(e.target?.value);
          }
        }
      },
      autoComplete: 'off',
      onFocus: () => {
        setPrevFocused(true);
        if (selectedItem === -1) {
          setSelectedItem(0);
        }
      },
      ...textFilterProps
    }
  );

  const customOnFilter = () => () => {
    // onFilter(value);
  }
  const tableDom = document.querySelector('.react-bootstrap-table');

  const calcLeft = () => {
    let left = 0;
    const paddingLeft = 318;
    const scrollLeft = tableDom.scrollLeft;
    if (filterDom && filterDom?.current) {
      const filterLeft = filterDom?.current?.input?.parentElement?.parentElement?.parentElement?.offsetLeft;
      left = filterLeft + paddingLeft - scrollLeft;
    }

    return left;
  }

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <div className="font-size-lg line-height-md">
          Start typing the full country name or search by short country code
        </div>
      }
    >
      <div onBlur={onBlur}>
        <filter.Filter ref={filterDom} {...filter.props} onFilter={customOnFilter} column={column}/>
        {(focused && filteredCountryOptions?.length) ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              className="rounded border border-light-primary bg-white" style={{
              maxWidth: '250px',
              position: 'absolute',
              zIndex: 1000000,
              left: `${calcLeft()}px`,
              maxHeight: '400px',
              overflowY: 'auto',
            }}
              ref={countryOptionsBlock}
              onClick={handleOnClick}
              onBlur={(e) => {
                e.stopPropagation();
                e.preventDefault()
              }}
            >
              {filteredCountryOptions
                .map((item, index) => (
                  <div
                    key={index}
                    onClick={handleClickOnItem}
                    onMouseEnter={() => setHoveredItemIndex(index)}
                    onMouseLeave={() => setHoveredItemIndex(-1)}
                    className={cs('d-flex align-items-center p-3 countryItem rounded cursor-pointer h-40px', {
                      'bg-primary text-white': (hoveredItemIndex === index || selectedItem === index),
                    })}
                    id={item.name}
                  >
                    <FlagImage
                      className="min-w-20px mr-3 max-w-20px"
                      countryCode={item?.code}
                    /> {item.name}
                  </div>
                ))}
            </div>
          </ClickAwayListener>
        ) : ''}
      </div>
    </Tooltip>
  )
};

export const textFilterOnBlurHelperWithAutoComplete = (
  onFilter,
  column,
  textFilterProps,
  actions,
  reducerName,
  notRemote = false,
  multi = false
) => {
  return (<CustomAutoComplete
    onFilter={onFilter}
    column={column}
    textFilterProps={textFilterProps}
    actions={actions}
    reducerName={reducerName}
    notRemote={notRemote}
    multi={multi}
  />);
};

const CustomAutoCompleteMulti = ({
  onFilter,
  column,
  textFilterProps,
  actions,
}) => {
  const [focused, setFocused] = useState(false);
  const [prevFocused, setPrevFocused] = useState(false);
  const [value, setValue] = useState(textFilterProps.defaultValue || '');
  const [hoveredItemIndex, setHoveredItemIndex] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [selectedItems, setSelectedItems] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const countryOptionsBlock = useRef(null);
  const filterDom = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (countryOptionsBlock?.current && scrollTop !== prevScrollTop) {
      setScrollTop(prevScrollTop)
      // eslint-disable-next-line no-unused-expressions
      countryOptionsBlock?.current?.scrollTo(0,prevScrollTop);
    }
  }, [prevScrollTop, scrollTop]);

  useEffect(() => {
    if (prevFocused !== focused) {
      setFocused(prevFocused);
      dispatch(actions.setUseCountryFieldSort(!prevFocused));
    }
  }, [actions, dispatch, focused, prevFocused]);

  const handleClickOnItem = (item) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(item.name);
  };
  const splitValueByComma = value.split(',');
  const lastItemInValue = splitValueByComma?.length ? splitValueByComma[splitValueByComma.length - 1] : '';

  const filteredCountryOptions = enums.countriesWithCode.filter((item) => (
    item.name.toLowerCase().startsWith(lastItemInValue.toLowerCase()) ||
    item.code.toLowerCase().includes(lastItemInValue.toLowerCase())
  ));

  const filteredCountries = filteredCountryOptions.map((item) => item.name);

  const handleOnClick = (e) => {
    if (focused) {
      e.stopPropagation();
      e.preventDefault();
      if (e.target.classList?.value?.includes('countryItem')) {
        setValue(e.target.id);
        const selectedCountry = filteredCountryOptions?.find((item) => (item.name === e.target.id))?.name || value;
        const newSelectedItems = [...selectedItems, selectedCountry];
        setSelectedItems(newSelectedItems);
        if (value.includes(selectedCountry)) {
          const newValue = value.split(selectedCountry).join('').replace(',,',',');
          setValue(newValue[0] === ',' ? newValue.slice(1, newValue.length) : newValue);
        } else {
          let newValue;
          if (value) {
            const splitValue = value.split(',');
            const valueWithoutLastItem = splitValue.slice(0, splitValue.length - 1).join(',');
            newValue = `${valueWithoutLastItem ? `${valueWithoutLastItem},` : ''}${selectedCountry},`;
          } else {
            newValue = `${selectedCountry},`;
          }
          setValue(newValue);
        }
      } else {
        // setValue(value);
        setPrevFocused(false);
      }
    }
  }

  const onBlur = (e) => {
    if (!e.relatedTarget) {
      setPrevFocused(false);
      const selectedCodes = enums.countriesWithCode?.filter((item) => (value.includes(item.name)))
        .map((item) => item.code?.toUpperCase()) || value.split(',').filter((item) => !!item);
      onFilter(selectedCodes);
    }
  }

  const handleClickAway = (e) => {
    if (e.path?.[0]?.id !== `text-filter-column-${column.dataField}` && focused) {
      setPrevFocused(false);
      const selectedCodes = enums.countriesWithCode?.filter((item) => (value.includes(item.name)))
        .map((item) => item.code?.toUpperCase()) || value.split(',').filter((item) => !!item);
      onFilter(selectedCodes);
    }
  };

  const filter = textFilter(
    {
      defaultValue: value,
      placeholder: ' ',
      delay: 1000000,
      onKeyUp: (e) => {
        if (e.target?.value?.length !== value?.length) {
          if (filteredCountryOptions?.length) {
            setSelectedItem(0);
            setPrevScrollTop(0);
          } else {
            setSelectedItem(-1);
          }
        }
        setValue(e.target?.value);
        if (e.keyCode === 40) {
          e.stopPropagation();
          e.preventDefault();

          if (selectedItem !== -1 && filteredCountryOptions?.length > selectedItem + 1) {
            setSelectedItem(selectedItem + 1);
            const optionHeight = 40;
            const maxVisibleCountryOptions = 10;
            const scrolledItems = scrollTop / optionHeight;
            if (countryOptionsBlock?.current && !((maxVisibleCountryOptions + scrolledItems) > (selectedItem + 1))) {
              setPrevScrollTop(scrollTop + optionHeight);
            }
          } else if (selectedItem === -1) {
            setSelectedItem(0);
          }
        }
        if (e.keyCode === 38) {
          e.stopPropagation();
          e.preventDefault();
          if (selectedItem > 0) {
            setSelectedItem(selectedItem - 1);
            const optionHeight = 40;
            const scrolledItems = scrollTop / optionHeight;
            if (countryOptionsBlock?.current && !((scrolledItems < (selectedItem - 1)))) {
              setPrevScrollTop(scrollTop - optionHeight);
            }
          } else if (selectedItem === -1) {
            setSelectedItem(1);
          }
        }
        if (e.keyCode === 13) {
          e.stopPropagation();
          e.preventDefault();
          if (selectedItem > -1) {
            const selectedCountry = filteredCountryOptions?.find((item, index) => (index === selectedItem))?.name || value
            const newSelectedItems = [...selectedItems, selectedCountry];
            setSelectedItems(newSelectedItems);
            if (value.includes(selectedCountry)) {
              const newValue = value.split(selectedCountry).join('').replace(',,',',');
              setValue(newValue[0] === ',' ? newValue.slice(1, newValue.length) : newValue);
            } else {
              let newValue;
              if (value) {
                const splitValue = value.split(',');
                const valueWithoutLastItem = splitValue.slice(0, splitValue.length - 1).join(',');
                newValue = `${valueWithoutLastItem ? `${valueWithoutLastItem},` : ''}${selectedCountry},`;
              } else {
                newValue = `${selectedCountry},`;
              }
              setValue(newValue);
            }
          }
        }
      },
      autoComplete: 'off',
      onFocus: () => {
        setPrevFocused(true);
      },
      ...textFilterProps
    }
  );

  const customOnFilter = () => () => {
    // onFilter(value);
  }
  const tableDom = document.querySelector('.react-bootstrap-table');

  const calcLeft = () => {
    let left = 0;
    const paddingLeft = 318;
    const scrollLeft = tableDom.scrollLeft;
    if (filterDom && filterDom?.current) {
      const filterLeft = filterDom?.current?.input?.parentElement?.parentElement?.parentElement?.offsetLeft;
      left = filterLeft + paddingLeft - scrollLeft;
    }

    return left;
  }

  const renderItemAction = (index, name) => {
    if (value.includes(name)) {
      return <MinusIcon className="ml-auto" />
    }
    if (hoveredItemIndex === index || selectedItem === index) {
      return <PlusIcon className="ml-auto" />
    }

    return '';
  };

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        <div className="font-size-lg line-height-md">
          Start typing the full country name or search by short country code
        </div>
      }
    >
      <div onBlur={onBlur}>
        <filter.Filter ref={filterDom} {...filter.props} onFilter={customOnFilter} column={column}/>
        {(focused && filteredCountryOptions?.length) ? (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div
              className="rounded border border-light-primary bg-white" style={{
              maxWidth: '250px',
              position: 'absolute',
              zIndex: 1000000,
              left: `${calcLeft()}px`,
              maxHeight: '400px',
              overflowY: 'auto',
            }}
              ref={countryOptionsBlock}
              onClick={handleOnClick}
              onBlur={(e) => {
                e.stopPropagation();
                e.preventDefault()
              }}
            >
              {enums.countriesWithCode
                .map((item, index) => (
                  <div
                    key={index}
                    style={!filteredCountries.includes(item.name) && value ? { display: 'none' } : undefined}
                    onClick={handleClickOnItem}
                    onMouseEnter={() => setHoveredItemIndex(index)}
                    onMouseLeave={() => setHoveredItemIndex(-1)}
                    className={cs('d-flex align-items-center p-3 countryItem rounded cursor-pointer h-40px', {
                      'bg-primary text-white': (hoveredItemIndex === index || selectedItems.includes(index) || selectedItem === index),
                    })}
                    id={item.name}
                  >
                    <FlagImage
                      className="min-w-20px mr-3 max-w-20px"
                      countryCode={item?.code}
                    /> {item.name}
                    {renderItemAction(index, item.name)}
                  </div>
                ))}
            </div>
          </ClickAwayListener>
        ) : ''}
      </div>
    </Tooltip>
  )
};

export const textFilterOnBlurHelperWithAutoCompleteMulti = (
  onFilter,
  column,
  textFilterProps,
  actions,
  reducerName
) => {
  return (<CustomAutoCompleteMulti
    onFilter={onFilter}
    column={column}
    textFilterProps={textFilterProps}
    actions={actions}
    reducerName={reducerName}
  />);
};

export const textFilterOnBlurHelper = (onFilter, column, textFilterProps) => {
  const customOnFilter = () => (value) => {
    onFilter(value);
  }
  const filter = textFilter(
    {
      defaultValue: '', placeholder: ' ', delay: 1000000, onBlur: (e) => {
        typeof onFilter === 'function' && onFilter(e.target.value);
      },
      onKeyUp: (e) => {
        if(e.keyCode === 13) {
          e.stopPropagation();
          e.preventDefault();
          customOnFilter()(e.target?.value);
        }
      },
      ...textFilterProps
    }
  );
  return (<filter.Filter {...filter.props} onFilter={customOnFilter} column={column}/>)
};

export const fetchReportHelper = (url) => async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });

  const results = await response.json();
  return results;
}

export const fetchReportAsCsvHelper = (url, fileName) => async (params) => {
  const response = await fetch(`${config.apiBaseUrl}${url}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  });
  const blob = await response.blob();
  const file = window.URL.createObjectURL(blob);
  var a = document.createElement('a');
  a.href = file;
  a.download = `${fileName}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
}

// reqParams can contain to jsons separated by space
// one of them should be empty
// TODO reconsider reqParams format in ST logging code
const sanitizeDoubleJSON = (str) => {
  if (str && str.startsWith('{}')) {
    return str.substring(3);
  }

  if (str && str.endsWith('{}')) {
    return str.substring(0, str.length - 2);
  }

  return str || '';
};

export const JsonCell = ({
  data,
  title,
  length = 50,
  styles = {},
  size = "lg",
  button = false,
  subTitle = ''
}) => {
  const [shown, setShown] = useState(false);

  const sanitizedData = sanitizeDoubleJSON(data);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sanitizedData).then(function() {
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  };

  return (
    <>
      {button ? (
        <button
          type="button"
          className="btn btn-primary btn-sm"
          onClick={() => setShown(true)}
        >
          Log
        </button>
      ) : (
        <div style={{ cursor: "pointer" }} onClick={() => setShown(true)}>
          {_.truncate(sanitizedData, { length })}
        </div>
      )}
      <Modal
        scrollable
        centered
        size={size}
        show={shown}
        onHide={() => setShown(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {title}
          </Modal.Title>
          <img
            style={{ cursor: 'pointer' }}
            alt="Copy to clipboard"
            title="Copy to clipboard"
            src={toAbsoluteUrl('/media/svg/icons/General/Clipboard.svg')}
            onClick={copyToClipboard}
          />
        </Modal.Header>
        <Modal.Body>
          {subTitle ? (
            <div className="w-100 pb-5 border-bottom mb-5">
              {subTitle}
            </div>
          ) : ''}
          <JSONPretty
            style={{ display: 'inline-block', ...styles }}
            id="json-pretty"
            json={sanitizedData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export const renderMappedField = (mapped) => {
  if (mapped === undefined) {
    return '-';
  }
  return (
    <div className={cs('px-2 py-2 rounded d-flex justify-content-center align-items-center', {
      'text-primary bg-light-primary': mapped,
      'text-muted bg-light-secondary': !mapped,
    })}>
      {_.capitalize(String(mapped))}
    </div>
  )
}

export const renderPreCodes = (precodes, options) => {
  if (!precodes?.length || !options?.length) {
    return <div/>;
  }

  precodes = precodes.sort();

  return (
    <div className="d-flex flex-column">
      {precodes.map((precode) => (
        <div key={precode}>{precode}</div>
      ))}
    </div>
  )
}

export const renderAnswerOptions = (precodes, options) => {
  if (!options?.length) {
    if (precodes?.length) {
      precodes = precodes.sort()
      return (
        <div className="d-flex flex-wrap">
          {precodes.map((precode) => (
            <span key={precode} className="mr-1">{precode};</span>
          ))}
        </div>
      )
    }
    return <div/>;
  }

  let approvedOptions = options.filter((option) => precodes?.includes(String(option.precode)));
  if (!approvedOptions?.length) {
    approvedOptions = options.filter((option, index) => precodes?.includes(String(index + 1)));
  }

  if (approvedOptions) {
    approvedOptions = approvedOptions.sort((a, b) => (a.precode - b.precode) || (a.optionText - b.optionText));
  }

  return (
    <div className="d-flex flex-column">
      {approvedOptions?.map((option) => {
        return (
        <div key={option.precode}>{option.optionText || option.precode};</div>
      )})}
    </div>
  )
}

export const dataURLtoFile = (dataUrl, filename) => {

  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, {type:mime});
}

export const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange
}) => (
  <div className="btn-group" role="group">
    {
      options.map(option => (
        <button
          key={ option.text }
          type="button"
          onClick={ () => onSizePerPageChange(option.page) }
          className={ `btn ${currSizePerPage === `${option.page}` ? 'btn-secondary' : 'btn-primary'}` }
        >
          { option.text }
        </button>
      ))
    }
  </div>
);

export const FraudScore = ({ fraudScore, level }) => {
  const content = (fraudScore || 'No');
  const color = {
    [enums.fraudScoreLevels.LOW]: '#000000',
    [enums.fraudScoreLevels.SOFT]: '#ff991f',
    [enums.fraudScoreLevels.HARD]: '#ff5630'
  }[level];
  return (
    <span style={{ color }}>{content}</span>
  );
}
