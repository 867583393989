import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { MonetizationOn, AttachMoney } from '@material-ui/icons';

export function AsideMenuList({ layoutProps }) {
  const user = useSelector(({ auth }) => auth.user);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const TranslationsMenu = () => (
    <><ul className={`menu-nav ${layoutProps.ulClasses}`}>
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(
          "/translations",
          true
        )}`}
        aria-haspopup="true"
        data-menu-toggle="hover"
      >
      <NavLink className="menu-link menu-toggle" to="/translations">
        <span className="svg-icon menu-icon">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Text.svg")} />
        </span>
        <span className="menu-text">Translation Manager</span>
        <i className="menu-arrow" />
      </NavLink>
      <div className="menu-submenu ">
        <i className="menu-arrow" />
        <ul className="menu-subnav">
          <li className={`menu-item ${getMenuItemActive("/translations/features")}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/translations/features">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}/>
              </span>
              <span className="menu-text">Features</span>
            </NavLink>
          </li>
          <li className={`menu-item ${getMenuItemActive("/translations/keys")}`} aria-haspopup="true">
            <NavLink className="menu-link" to="/translations/keys">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}/>
              </span>
              <span className="menu-text">Keys</span>
            </NavLink>
          </li>
        </ul>
      </div>
      </li>
    </ul>
    </>
  );

  if (user?.role) {
    return <TranslationsMenu />;
  }

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item ${getMenuItemActive("/dashboard")}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/report-builder")}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/report-builder">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Equalizer.svg")}/>
            </span>
            <span className="menu-text">Report Builder</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/surveys")}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/surveys">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")}/>
            </span>
            <span className="menu-text">Surveys</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/clicks")}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/clicks">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Align-justify.svg")}/>
            </span>
            <span className="menu-text">Clicks Report</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/users")}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")}/>
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>

        <li className={`menu-item ${getMenuItemActive("/orders")}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/orders">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}/>
            </span>
            <span className="menu-text">Orders</span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/rewards",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/rewards">
            <span className="svg-icon menu-icon">
              <MonetizationOn />
            </span>
            <span className="menu-text">Rewards</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/rewards-management")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/rewards-management">
                  <span className="svg-icon menu-icon">
                    <AttachMoney />
                  </span>
                  <span className="menu-text">Rewards Management</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/amazon-inventory")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/amazon-inventory">
                  <span className="svg-icon menu-icon">
                    <i className="fab fa-brands fa-amazon" />
                  </span>
                  <span className="menu-text">Amazon Inventory</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/bitcoin-records")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/bitcoin-records">
                  <span className="svg-icon menu-icon">
                    <i className="fab fa-brands fa-bitcoin" />
                  </span>
                  <span className="menu-text">Bitcoin Records</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/logs",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/logs">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")} />
            </span>
            <span className="menu-text">Logs</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/high-reversal-rate-bans")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/high-reversal-rate-bans">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Expand-arrows.svg")}/>
                  </span>
                  <span className="menu-text">High Reversal Rate Bans</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/veriff-log")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/veriff-log">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Shield-disabled.svg")}/>
                  </span>
                  <span className="menu-text">Veriff Log</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/veriff-blocks")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/veriff-blocks">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Error-circle.svg")}/>
                  </span>
                  <span className="menu-text">Veriff Callbacks - Blocked Users</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/api-log")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/api-log">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/mobile.svg")}/>
                  </span>
                  <span className="menu-text">User Actions Log</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/banned-users")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/banned-users">
                  <span className="svg-icon menu-icon">
                   <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Hummer2.svg")}/>
                  </span>
                  <span className="menu-text">Banned Users List</span>
                </NavLink>
               </li>

              <li className={`menu-item ${getMenuItemActive("/survey-list-results-log")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/survey-list-results-log">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Toggle-Right.svg")}/>
                  </span>
                  <span className="menu-text">Survey List Results Log</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/survey-updates-log")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/survey-updates-log">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}/>
                  </span>
                  <span className="menu-text">Survey Updates Log</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/toluna-notifications-log")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/toluna-notifications-log">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Align-justify.svg")}/>
                  </span>
                  <span className="menu-text">Toluna IPES Notifications</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/retention-features",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/retention-features">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Media/Repeat.svg")}/>
            </span>
            <span className="menu-text">Retention Features</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/atoms-management")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/atoms-management">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-polygon.svg")}/>
                  </span>
                  <span className="menu-text">Atoms Management</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/questions-manager",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/questions-management">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")} />
            </span>
            <span className="menu-text">Questions Management</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/questions")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/questions">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")}/>
                  </span>
                  <span className="menu-text">Questions</span>
                </NavLink>
              </li>

              <li className={`menu-item ${getMenuItemActive("/profiler-order")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/profiler-order">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Up-down.svg")}/>
                  </span>
                  <span className="menu-text">Profiler Order</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>

        <li className={`menu-item ${getMenuItemActive("/reviews")}`} aria-haspopup="true">
          <NavLink className="menu-link" to="/reviews">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Like.svg")}/>
            </span>
            <span className="menu-text">Reviews</span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/miscellaneous",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/miscellaneous">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-1.svg")} />
            </span>
            <span className="menu-text">Miscellaneous</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/miscellaneous/export-user-data")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/miscellaneous/export-user-data">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Expand-arrows.svg")}/>
                  </span>
                  <span className="menu-text">Export User Data</span>
                </NavLink>
              </li>
            </ul>
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/miscellaneous/qa-helpers")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/miscellaneous/qa-helpers">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}/>
                  </span>
                  <span className="menu-text">QA Helpers</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/translation-manager",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/translations">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Text.svg")} />
            </span>
            <span className="menu-text">Translation Manager</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className={`menu-item ${getMenuItemActive("/translations/languages")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/translations/languages">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Align-auto.svg")}/>
                  </span>
                  <span className="menu-text">Languages</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/translations/translators")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/translations/translators">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}/>
                  </span>
                  <span className="menu-text">Translators</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/translations/translator-payments")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/translations/translator-payments">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}/>
                  </span>
                  <span className="menu-text">Translator Payments</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/translations/products")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/translations/products">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Folder.svg")}/>
                  </span>
                  <span className="menu-text">Products</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/translations/features")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/translations/features">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Edit.svg")}/>
                  </span>
                  <span className="menu-text">Features</span>
                </NavLink>
              </li>
              <li className={`menu-item ${getMenuItemActive("/translations/keys")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/translations/keys">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Key.svg")}/>
                  </span>
                  <span className="menu-text">Keys</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
