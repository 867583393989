import React  from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Container, Card, CardContent, Grid, Button } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { actions } from '~/app/modules/VeriffBlocks/actions';
import VeriffBlocksReport from '~/app/modules/VeriffBlocks/VeriffBlocks.report';
import enums from "~/app/helpers/enums";

const DateSelectors = () => {
  const startDate = useSelector(state => state.veriffBlocks.startDate);
  const endDate = useSelector(state => state.veriffBlocks.endDate);
  const dispatch = useDispatch();

  return (
    <>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="start-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="Start Date"
          value={startDate}
          onChange={(_, v) => dispatch(actions.setStartDate(v))}
        />
      </Grid>
      <Grid item xs={2}>
        <KeyboardDatePicker
          autoOk
          id="end-date"
          disableToolbar
          disableFuture
          variant="inline"
          format={enums.DATE_FORMAT}
          margin="none"
          label="End Date"
          value={endDate}
          onChange={(_, v) => dispatch(actions.setEndDate(v))}
        />
      </Grid>
    </>
  )
};

const ReportParamsForm = () => {
  const dispatch = useDispatch();

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4} alignItems="flex-end">
          <DateSelectors />
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => dispatch(actions.generateReport())}
            >
              Generate
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const VeriffBlocks = () => (
  <Container>
    <ReportParamsForm />
    <VeriffBlocksReport />
  </Container>
);

export default VeriffBlocks;


