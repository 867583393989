import { countryCodes } from './values/countryCodes';
import { userRegistrationDevices } from './values/registrationDevices';
import { userRegistrationPlatform } from './values/registrationPlatform';
import enums from '../../helpers/enums';

export const placeholder = {
  fields: 'typing a field name',
  operators: 'Enter an operator',
  values: 'Start typing a value'
};

export const operators = {
  string: [
    { value: 'ILIKE', label: 'is' },
    { value: '!=', label: 'is not' }
  ],
  number: [
    { value: '=', label: 'is' },
    { value: '>', label: 'is greater than' },
    { value: '>=', label: 'is greater than or equal to' },
    { value: '<', label: 'is less than' },
    { value: '<=', label: 'is less than or equal to' },
    // { value: 'BETWEEN', label: 'is between' }
  ],
  date: [
    { value: '<', label: 'is before' },
    { value: '>', label: 'is after' },
    // { value: 'BETWEEN', label: 'is between' }
  ],
  enum: [
    { value: '=', label: 'is' },
    { value: '!=', label: 'is not' }
  ]
};

const userDimensionsList = [
  {
    name: 'userCountryCode',
    label: 'Country Code',
    type: 'enum',
    choicesMap: countryCodes
  },
  {
    name: 'userRegistrationPlatform',
    label: 'Registration Platform',
    type: 'enum',
    choicesMap: userRegistrationPlatform
  },
  {
    name: 'userRegistrationOSVersion',
    label: 'Registration OS Version',
    type: 'enum',
    choicesMap: [
      {
        name: 'n/a',
        label: 'n/a',
        value: 'n/a'
      },
      {
        name: 'desktop',
        label: 'desktop',
        value: 'desktop'
      },
      {
        name: 'AndroidOS',
        label: 'AndroidOS',
        value: 'AndroidOS'
      },
      {
        name: 'iOS',
        label: 'iOS',
        value: 'iOS'
      },
      {
        name: 'WindowsPhoneOS',
        label: 'WindowsPhoneOS',
        value: 'WindowsPhoneOS'
      },
      {
        name: 'SymbianOS',
        label: 'SymbianOS',
        value: 'SymbianOS'
      },
      {
        name: 'BlackBerryOS',
        label: 'BlackBerryOS',
        value: 'BlackBerryOS'
      },
      {
        name: 'JavaOS',
        label: 'JavaOS',
        value: 'JavaOS'
      },
      {
        name: 'WindowsMobileOS',
        label: 'WindowsMobileOS',
        value: 'WindowsMobileOS'
      }
    ]
  },
  {
    name: 'userRegistrationPublisherId',
    label: 'Registration Publisher ID',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'userAge',
    label: 'Age',
    type: 'number',
    choicesMap: []
  },
  {
    name: 'userGender',
    label: 'Gender',
    type: 'enum',
    choicesMap: [
      {
        name: 'male',
        value: 'male',
        label: 'male'
      },
      {
        name: 'female',
        value: 'female',
        label: 'female'
      },
      {
        name: 'n/a',
        value: 'n/a',
        label: 'n/a'
      }
    ]
  },
  {
    name: 'userHasMobileApp',
    label: 'Has Mobile App?',
    type: 'enum',
    choicesMap: [
      {
        name: 'Yes',
        value: '1',
        label: 'Yes'
      },
      {
        name: 'No',
        value: '0',
        label: 'No'
      }
    ]
  },
  {
    name: 'userLastProfilerQuestionId',
    label: 'Last Profiler Question ID',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'userRegistrationDevice',
    label: 'Registration Device',
    type: 'enum',
    choicesMap: userRegistrationDevices
  },
  {
    name: 'userProfilerStatus',
    label: 'Profiler Status',
    type: 'enum',
    choicesMap: [
      {
        name: 'Finished',
        value: 'finished',
        label: 'Finished'
      },
      {
        name: 'Started',
        value: 'started',
        label: 'Started'
      },
      {
        name: 'Not Started',
        value: 'not_started',
        label: 'Not Started'
      }
    ]
  },
  {
    name: 'userIsBanned',
    label: 'Is Banned?',
    type: 'enum',
    choicesMap: [
      {
        name: 'Yes',
        value: '1',
        label: 'Yes'
      },
      {
        name: 'No',
        value: '0',
        label: 'No'
      }
    ]
  },
  {
    name: 'userBanReason',
    label: 'Ban Reason',
    type: 'enum',
    choicesMap: [
      { name: '', value: '', label: '' },
      { name: 'under_16', value: 'under_16', label: 'under_16' },
      {
        name: 'same_payment_account_of_banned_user',
        value: 'same_payment_account_of_banned_user',
        label: 'same_payment_account_of_banned_user'
      },
      {
        name: 'verified_country_does_not_match',
        value: 'verified_country_does_not_match',
        label: 'verified_country_does_not_match'
      },
      {
        name: 'same_document_banned_user',
        value: 'same_document_banned_user',
        label: 'same_document_banned_user'
      },
      {
        name: 'manually_banned',
        value: 'manually_banned',
        label: 'manually_banned'
      },
      {
        name: 'same_document_recently_approved',
        value: 'same_document_recently_approved',
        label: 'same_document_recently_approved'
      },
      {
        name: 'ghost_completion_detected',
        value: 'ghost_completion_detected',
        label: 'ghost_completion_detected'
      },
      { name: 'banned', value: 'banned', label: 'banned' },
      {
        name: 'same_payment_account_of_recent_user',
        value: 'same_payment_account_of_recent_user',
        label: 'same_payment_account_of_recent_user'
      },
      {
        name: 'high_reversal_rate',
        value: 'high_reversal_rate',
        label: 'high_reversal_rate'
      },
      {
        name: 'verified_age_too_off',
        value: 'verified_age_too_off',
        label: 'verified_age_too_off'
      }
    ]
  },
  {
    name: 'userFunnelStatus',
    label: 'Funnel Status',
    type: 'enum',
    choicesMap: [
      {
        name: 'redeemed',
        label: 'redeemed',
        value: 'redeemed'
      },
      {
        name: 'profiler_started',
        label: 'profiler_started',
        value: 'profiler_started'
      },
      {
        name: 'profiler_completed',
        label: 'profiler_completed',
        value: 'profiler_completed'
      },
      {
        name: 'registered',
        label: 'registered',
        value: 'registered'
      },
      {
        name: 'paid',
        label: 'paid',
        value: 'paid'
      },
      {
        name: 'clicked',
        label: 'clicked',
        value: 'clicked'
      },
      {
        name: 'converted',
        label: 'converted',
        value: 'converted'
      }
    ]
  },
  {
    name: 'userFraudScoreStatus',
    label: 'Fraud Score Status',
    type: 'enum',
    choicesMap: [
      {
        name: 'n/a',
        label: 'n/a',
        value: 'n/a'
      },
      {
        name: 'below_soft',
        label: 'below_soft',
        value: 'below_soft'
      },
      {
        name: 'between_soft_and_hard',
        label: 'between_soft_and_hard',
        value: 'between_soft_and_hard'
      },
      {
        name: 'above_hard',
        label: 'above_hard',
        value: 'above_hard'
      }
    ]
  },
  {
    name: 'userFraudScoreBucket',
    label: 'Fraud Score Bucket',
    type: 'number',
    choicesMap: []
  },
  {
    name: 'userVeriffStatus',
    label: 'Veriff Status',
    type: 'enum',
    choicesMap: [
      { name: 'n/a', label: 'n/a', value: 'n/a' },
      { name: 'generated', label: 'generated', value: 'generated' },
      { name: 'started', label: 'started', value: 'started' },
      { name: 'submitted', label: 'submitted', value: 'submitted' },
      {
        name: 'resubmitted',
        label: 'resubmitted',
        value: 'resubmitted'
      },
      { name: 'declined', label: 'declined', value: 'declined' },
      { name: 'expired', label: 'expired', value: 'expired' },
      { name: 'approved', label: 'approved', value: 'approved' }
    ]
  },
  {
    name: 'userEmailVerified',
    label: 'Email Verified?',
    type: 'enum',
    choicesMap: [
      {
        name: 'Yes',
        value: '1',
        label: 'Yes'
      },
      {
        name: 'No',
        value: '0',
        label: 'No'
      }
    ]
  },
  {
    name: 'userPhoneVerified',
    label: 'Phone Verified?',
    type: 'enum',
    choicesMap: [
      {
        name: 'Yes',
        value: '1',
        label: 'Yes'
      },
      {
        name: 'No',
        value: '0',
        label: 'No'
      }
    ]
  }
];

const clickDimensionsList = [
  {
    name: 'clickPublisherId',
    label: 'Click Publisher Id',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'clickSubId',
    label: 'Click SubId',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'clickSubId1',
    label: 'Click SubId1',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'clickSubId2',
    label: 'Click SubId2',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'clickSubId3',
    label: 'Click SubId3',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'clickPlatform',
    label: 'Click Platform',
    type: 'enum',
    choicesMap: [
      {
        name: 'n/a',
        label: 'n/a',
        value: ''
      },
      {
        name: 'Desktop - Unknown',
        label: 'Desktop - Unknown',
        value: 'Desktop - Unknown'
      },
      {
        name: 'Desktop - Linux',
        label: 'Desktop - Linux',
        value: 'Desktop - Linux'
      },
      {
        name: 'Mobile Web - Android',
        label: 'Mobile Web - Android',
        value: 'Mobile Web - Android'
      },
      {
        name: 'Desktop - Windows',
        label: 'Desktop - Windows',
        value: 'Desktop - Windows'
      },
      {
        name: 'Mobile Web - iOS',
        label: 'Mobile Web - iOS',
        value: 'Mobile Web - iOS'
      },
      {
        name: 'Desktop - Mac OS',
        label: 'Desktop - Mac OS',
        value: 'Desktop - Mac OS'
      },
      {
        name: 'Desktop - Chromium OS',
        label: 'Desktop - Chromium OS',
        value: 'Desktop - Chromium OS'
      },
      {
        name: 'Desktop - Ubuntu',
        label: 'Desktop - Ubuntu',
        value: 'Desktop - Ubuntu'
      },
      {
        name: 'Mobile Web - Linux',
        label: 'Mobile Web - Linux',
        value: 'Mobile Web - Linux'
      },
      {
        name: 'Desktop - Android',
        label: 'Desktop - Android',
        value: 'Desktop - Android'
      },
      {
        name: 'Desktop - Mint',
        label: 'Desktop - Mint',
        value: 'Desktop - Mint'
      },
      {
        name: 'Mobile App - Android',
        label: 'Mobile App - Android',
        value: 'Mobile App - Android'
      },
      {
        name: 'Mobile App - iOS',
        label: 'Mobile App - iOS',
        value: 'Mobile App - iOS'
      },
      {
        name: 'Mobile App - Unknown',
        label: 'Mobile App - Unknown',
        value: 'Mobile App - Unknown'
      },
      {
        name: 'Mobile Web - Windows Phone',
        label: 'Mobile Web - Windows Phone',
        value: 'Mobile Web - Windows Phone'
      },
      {
        name: 'Desktop - Fedora',
        label: 'Desktop - Fedora',
        value: 'Desktop - Fedora'
      },
      {
        name: 'Desktop - Raspbian',
        label: 'Desktop - Raspbian',
        value: 'Desktop - Raspbian'
      },
      {
        name: 'Mobile Web - HarmonyOS',
        label: 'Mobile Web - HarmonyOS',
        value: 'Mobile Web - HarmonyOS'
      },
      {
        name: 'Desktop - Xbox',
        label: 'Desktop - Xbox',
        value: 'Desktop - Xbox'
      },
      {
        name: 'Desktop - PlayStation',
        label: 'Desktop - PlayStation',
        value: 'Desktop - PlayStation'
      },
      {
        name: 'Mobile Web - Windows',
        label: 'Mobile Web - Windows',
        value: 'Mobile Web - Windows'
      },
      {
        name: 'Desktop - Tizen',
        label: 'Desktop - Tizen',
        value: 'Desktop - Tizen'
      },
      {
        name: 'Desktop - openSUSE',
        label: 'Desktop - openSUSE',
        value: 'Desktop - openSUSE'
      },
      {
        name: 'Mobile Web - KAIOS',
        label: 'Mobile Web - KAIOS',
        value: 'Mobile Web - KAIOS'
      },
      {
        name: 'Mobile Web - Tizen',
        label: 'Mobile Web - Tizen',
        value: 'Mobile Web - Tizen'
      },
      {
        name: 'Mobile Web - BlackBerry',
        label: 'Mobile Web - BlackBerry',
        value: 'Mobile Web - BlackBerry'
      },
      {
        name: 'Mobile Web - Android-x86',
        label: 'Mobile Web - Android-x86',
        value: 'Mobile Web - Android-x86'
      },
      {
        name: 'Desktop - Solaris',
        label: 'Desktop - Solaris',
        value: 'Desktop - Solaris'
      },
      {
        name: 'Desktop - FreeBSD',
        label: 'Desktop - FreeBSD',
        value: 'Desktop - FreeBSD'
      },
      {
        name: 'Mobile Web - Firefox OS',
        label: 'Mobile Web - Firefox OS',
        value: 'Mobile Web - Firefox OS'
      },
      {
        name: 'Desktop - Gentoo',
        label: 'Desktop - Gentoo',
        value: 'Desktop - Gentoo'
      },
      {
        name: 'Desktop - windows',
        label: 'Desktop - windows',
        value: 'Desktop - windows'
      },
      {
        name: 'Mobile Web - Unknown',
        label: 'Mobile Web - Unknown',
        value: 'Mobile Web - Unknown'
      },
      {
        name: 'Mobile Web - Symbian',
        label: 'Mobile Web - Symbian',
        value: 'Mobile Web - Symbian'
      },
      {
        name: 'Desktop - Nintendo',
        label: 'Desktop - Nintendo',
        value: 'Desktop - Nintendo'
      },
      {
        name: 'Desktop - NetBSD',
        label: 'Desktop - NetBSD',
        value: 'Desktop - NetBSD'
      },
      {
        name: 'Desktop - OpenBSD',
        label: 'Desktop - OpenBSD',
        value: 'Desktop - OpenBSD'
      },
      {
        name: 'Desktop - PLAYSTATION',
        label: 'Desktop - PLAYSTATION',
        value: 'Desktop - PLAYSTATION'
      },
      {
        name: 'Desktop - iOS',
        label: 'Desktop - iOS',
        value: 'Desktop - iOS'
      },
      {
        name: 'Desktop - tizen',
        label: 'Desktop - tizen',
        value: 'Desktop - tizen'
      },
      {
        name: 'Mobile Web - Mac OS',
        label: 'Mobile Web - Mac OS',
        value: 'Mobile Web - Mac OS'
      },
      {
        name: 'Desktop - Unix',
        label: 'Desktop - Unix',
        value: 'Desktop - Unix'
      },
      {
        name: 'Desktop - OS/2',
        label: 'Desktop - OS/2',
        value: 'Desktop - OS/2'
      },
      {
        name: 'Mobile Web - Windows Phone OS',
        label: 'Mobile Web - Windows Phone OS',
        value: 'Mobile Web - Windows Phone OS'
      },
      {
        name: 'Desktop - gnu',
        label: 'Desktop - gnu',
        value: 'Desktop - gnu'
      },
      {
        name: 'Desktop - Chromecast',
        label: 'Desktop - Chromecast',
        value: 'Desktop - Chromecast'
      },
      {
        name: 'Desktop - Debian',
        label: 'Desktop - Debian',
        value: 'Desktop - Debian'
      },
      {
        name: 'Desktop - qnx',
        label: 'Desktop - qnx',
        value: 'Desktop - qnx'
      },
      {
        name: 'Desktop - Firefox OS',
        label: 'Desktop - Firefox OS',
        value: 'Desktop - Firefox OS'
      },
      {
        name: 'Mobile Web - RIM Tablet OS',
        label: 'Mobile Web - RIM Tablet OS',
        value: 'Mobile Web - RIM Tablet OS'
      },
      {
        name: 'Desktop - GNU',
        label: 'Desktop - GNU',
        value: 'Desktop - GNU'
      },
      {
        name: 'Desktop - WIndows',
        label: 'Desktop - WIndows',
        value: 'Desktop - WIndows'
      },
      {
        name: 'Desktop - CentOS',
        label: 'Desktop - CentOS',
        value: 'Desktop - CentOS'
      },
      {
        name: 'Desktop - Red Hat',
        label: 'Desktop - Red Hat',
        value: 'Desktop - Red Hat'
      }
    ]
  },
  {
    name: 'clickSurveyProvider',
    label: 'Survey Provider',
    type: 'enum',
    choicesMap: [
      {
        name: 'toluna',
        label: 'toluna',
        value: 'toluna'
      },
      {
        name: 'federated',
        label: 'federated',
        value: 'federated'
      },
      {
        name: 'ssi',
        label: 'ssi',
        value: 'ssi'
      },
      {
        name: 'innovate',
        label: 'innovate',
        value: 'innovate'
      },
      {
        name: 'pureSpectrum',
        label: 'pureSpectrum',
        value: 'pureSpectrum'
      },
      {
        name: 'ssi2',
        label: 'ssi2',
        value: 'ssi2'
      },
      {
        name: 'paradigm',
        label: 'paradigm',
        value: 'paradigm'
      },
      {
        name: 'ipsos',
        label: 'ipsos',
        value: 'ipsos'
      },
      {
        name: 'borderlessAccess',
        label: 'borderlessAccess',
        value: 'borderlessAccess'
      },
      {
        name: 'marketCube2',
        label: 'marketCube2',
        value: 'marketCube2'
      },
      {
        name: 'prodege',
        label: 'prodege',
        value: 'prodege'
      },
      {
        name: 'gmo',
        label: 'gmo',
        value: 'gmo'
      },
      {
        name: 'pollfish',
        label: 'pollfish',
        value: 'pollfish'
      },
      {
        name: 'morningConsult',
        label: 'morningConsult',
        value: 'morningConsult'
      },
      {
        name: 'ssiRex',
        label: 'ssiRex',
        value: 'ssiRex'
      },
      {
        name: 'globalSurvey',
        label: 'globalSurvey',
        value: 'globalSurvey'
      },
      {
        name: 'n/a',
        label: 'n/a',
        value: ''
      },
      {
        name: 'qualtrics',
        label: 'qualtrics',
        value: 'qualtrics'
      },
      {
        name: 'marketCube',
        label: 'marketCube',
        value: 'marketCube'
      },
      {
        name: 'paneland',
        label: 'paneland',
        value: 'paneland'
      },
      {
        name: 'lightspeed',
        label: 'lightspeed',
        value: 'lightspeed'
      },
      {
        name: 'precision',
        label: 'precision',
        value: 'precision'
      },
      {
        name: 'cint',
        label: 'cint',
        value: 'cint'
      },
    ]
  },
  {
    name: 'clickSurveySubProvider',
    label: 'Survey Sub Provider',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'clickSurveyId',
    label: 'Click Survey ID',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'clickOrigin',
    label: 'Click Origin',
    type: 'enum',
    choicesMap: [
      '',
      'email-post',
      'email',
      'email-new-0-post',
      'email-reminder-post',
      'email-new-0',
      'email-reminder',
      'push-post',
      'push',
      'push-new-0-post',
      'push-new-0',
      'push-reminder-post',
      'push-reminder',
      'email-new-3',
      'email-new-3-post',
      'email-new-1-post',
      'email-new-1',
      'email-new-2',
      'sms-post',
      'email-new-post',
      'sms',
      'email-new-2-post',
      'email-new',
      'sms-new-0',
      'push-new-post',
      'extension',
      'push-new-1',
      'injection-post',
      'push-new-1-post',
      'sms-new-0-post',
      'sms-new-post',
      'push-new-2',
      'sms-new',
      'sms-new-3',
      'push-new-3',
      'injection',
      'push-new-3-post',
      'push-new-2-post',
      'sms-new-2',
      'sms-new-1',
      'sms-new-3-post',
      'html-injection-post',
      'sms-new-2-post',
      'sms-new-1-post',
      'push-new',
      // 'null',
      // 'push-remi',
      // 'email-ne',
      // 'push-reminderg',
      // 'email-new-1000',
      // 'html-injection',
      // 'push-new-0https://surveytime.io/surveys/eyJpZCI6Ijc5MTZiMDM5LTQ0MWUtNGUyNy05MzhkLTRmZDNkNzBhOWUwYiIsImV4dHJhUXVlc3Rpb25zIjpbXX0?pt=check',
      // 'email1',
      // 'email-',
      // 'kita bisaemail',
      // 'push,',
      // 'push-new-0https://surveytime.io/surveys/eyJpZCI6IjMxMjA0ZDhkLWFmMjctNGFmOS05MjU4LTE5NTNkMjM5MjAwYSIsImV4dHJhUXVlc3Rpb25zIjpbXX0?pt=check',
      // 'https://api.surveytime.io/s/774493b12aemail-new-0',
      // 'xstar.mods@outlook.fr',
      // 'push-new-0/page/2',
      // 'chfu-afj-3',
    ].map((origin) => ({
      name: origin,
      label: origin,
      value: origin,
    })),
  },
  {
    name: 'clickBruttoEarned',
    label: 'Click Gross Revenue',
    type: 'number',
    choicesMap: [],
  },
  {
    name: 'clickNettoEarned',
    label: 'Click Net Revenue',
    type: 'number',
    choicesMap: [],
  },
  {
    name: 'clickSurveyCPIBucket',
    label: 'Survey CPI Bucket',
    type: 'number',
    choicesMap: []
  },
  {
    name: 'clickProviderStatus',
    label: 'Click Provider Status',
    type: 'string',
    choicesMap: []
  },
  {
    name: 'clickProviderTerm',
    label: 'Click Provider Term',
    type: 'string',
    choicesMap: []
  },
];

const orderDimensionsList = [
  {
    name: 'orderRedeemMethod',
    label: 'Order Redeem Method',
    type: 'enum',
    choicesMap: [
      {
        name: 'n/a',
        label: 'n/a',
        value: ''
      },
      {
        name: 'paypal',
        label: 'paypal',
        value: 'paypal'
      },
      {
        name: 'tangocard',
        label: 'tangocard',
        value: 'tangocard'
      },
      {
        name: 'amazon',
        label: 'amazon',
        value: 'amazon'
      },
      {
        name: 'bitcoin',
        label: 'bitcoin',
        value: 'bitcoin'
      },
      {
        name: 'wincube',
        label: 'wincube',
        value: 'wincube'
      },
      {
        name: 'tremendous',
        label: 'tremendous',
        value: 'tremendous'
      },
    ],
  },
];

const statusDimensionsAddition = [
  {
    name: 'orderStatus',
    label: 'Order Status',
    type: 'enum',
    choicesMap: [
      {
        name: 'Created',
        value: 'created',
        label: 'Created'
      },
      {
        name: 'Rejected',
        value: 'rejected',
        label: 'Rejected'
      },
      {
        name: 'Paid',
        value: 'paid',
        label: 'Paid'
      },
      {
        name: 'Waiting Change',
        value: 'waiting_change',
        label: 'Waiting Change'
      },
      {
        name: 'On Hold',
        value: 'on_hold',
        label: 'On Hold'
      },
      {
        name: 'Rejected LOI',
        value: 'rejected_loi',
        label: 'Rejected LOI'
      },
    ],
  },
  {
    name: 'clickStatus',
    label: 'Click Status',
    type: 'enum',
    choicesMap: [
      {
        name: 'Pending',
        value: 'pending',
        label: 'Pending'
      },
      {
        name: 'Stopped',
        value: 'stopped',
        label: 'Stopped'
      },
      {
        name: 'Preliminary Failed',
        value: 'preliminaryFailed',
        label: 'Preliminary Failed'
      },
      {
        name: 'Success',
        value: 'success',
        label: 'Success'
      },
      {
        name: 'Waiting Verification',
        value: 'waitingVerification',
        label: 'Waiting Verification'
      },
      {
        name: 'Reversal',
        value: 'reversal',
        label: 'Reversal'
      },
      {
        name: 'DQ',
        value: 'dq',
        label: 'DQ'
      },
      {
        name: 'LOI Reversal',
        value: 'loi_reversal',
        label: 'LOI Reversal'
      },
    ],
  },
];

const virtualDimensionsList = [
  {
    name: 'attributedPublisherId',
    label: 'Attributed Publisher ID',
    type: 'string',
    choicesMap: []
  },
];

export const fieldsList = {
  [enums.reportBuilderType.ACTIVITY]: [
    {
      name: 'createDate',
      label: 'Date',
      type: 'string',
      choicesMap: []
    },
    ...virtualDimensionsList,
    ...userDimensionsList,
    ...clickDimensionsList,
    ...orderDimensionsList,
    ...statusDimensionsAddition,
  ],
  [enums.reportBuilderType.COHORT]: [
    ...virtualDimensionsList,
    ...userDimensionsList,
    ...clickDimensionsList,
    ...orderDimensionsList,
    ...statusDimensionsAddition,
  ],
  [enums.reportBuilderType.MEDIA_SPEND]: [
    {
      name: 'createDate',
      label: 'Date',
      type: 'string',
      choicesMap: []
    },
    ...virtualDimensionsList,
    userDimensionsList.find((d) => d.name === 'userCountryCode'),
    clickDimensionsList.find((d) => d.name === 'clickPlatform'),
  ],
};
