import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "~/_metronic/_helpers";
import { actions } from "~/app/modules/User/actions";
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  Card, CardContent, Grid, Tooltip
} from "@material-ui/core";
import MonthChart from "../MonthChart";
import BootstrapTable from "react-bootstrap-table-next";
import { newDateFormatLong } from "~/app/helpers";
import { sortCaret, headerSortingClasses } from "~/_metronic/_helpers";
import { Link } from 'react-router-dom';
import enums from "~/app/helpers/enums";

const renderOrderStatus = (status) => {
  let className = 'px-3 py-2 rounded text-center';

  switch (status) {
    case 'pending':
      className += ' text-warning bg-light-warning';
      break;

    case 'paid':
      className += ' text-success bg-light-success';
      break;

    case 'rejected:':
      className += ' text-danger bg-light-danger';
      break;

    default:
      className += ' text-info bg-light-info';
      break;
  }

  return (<div className={className}>{`${status[0].toUpperCase()}${status.slice(1)}`}</div>)
}

const findPaymentMethod = (paymentMethod, row) => {
  if(!paymentMethod) {
    return '-'
  }

  if (paymentMethod === 'wincube' && enums.wincubeCardIcons[row?.cardId]) {
    return (
      <SVG
        key={paymentMethod}
        className="h-25px w-25px"
        src={toAbsoluteUrl(enums.wincubeCardIcons[row?.cardId])}
      />
    )
  }

  if (paymentMethod === 'tangocard') {
    return (
      <Tooltip title={row.paymentDescription}>
        <img alt={paymentMethod} src={row.paymentImage} className="max-w-60px max-h-40px" />
      </Tooltip>
    );
  }

  return (
    <SVG
      key={paymentMethod}
      className="h-25px w-25px"
      src={toAbsoluteUrl(enums.paymentMethodIcons[paymentMethod])}
    />
  )
}

const OrdersTable =({entities}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(entities);
  }, [entities])

  const getHandlerTableChange = (setData) => {
    return (type, { sortField, sortOrder }) => {
      const newData = tableData.sort((a, b) => (
        sortOrder === 'asc' ? (a[sortField] - b[sortField]) : (b[sortField] - a[sortField])
      ))
      setData(newData)
    };
  }

  const columns = [
    { dataField: "_id", text: "Order ID", sortCaret: sortCaret, headerSortingClasses, sort: true },
    {
      dataField: "placementDate",
      text: "Order Placement Date",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value) => newDateFormatLong(value),
      sort: true,
    },
    {
      dataField: "paymentDate",
      text: "Order Payment Date",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (value) => newDateFormatLong(value),
      sort: true,
    },
    {
      dataField: "orderStatus",
      text: "Order Status",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (val) => renderOrderStatus(val),
      sort: true,
    },
    {
      dataField: "verificationStatus",
      text: "Order Verification Status",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
    },
    {
      dataField: "payout",
      text: "Payout",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
    },
    {
      dataField: "paymentMethod",
      text: "Payment Method",
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (val, row) => findPaymentMethod(val, row),
      sort: true,
    },
    {
      dataField: "paymentAccount",
      text: "Payment account",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
    },
    {
      dataField: "redemptionCode",
      text: "Redemption Code",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
    },
    {
      dataField: "clickId",
      text: "Click ID",
      sortCaret: sortCaret,
      headerSortingClasses,
      sort: true,
    },
    {  dataField: "action", text: "Action"  },
  ];

  return (
    <BootstrapTable
      wrapperClasses="table-responsive max-h-400px mb-7"
      bordered={true}
      classes="table table-vertical-top overflow-hidden"
      bootstrap4
      size="sm"
      responsive="lg"
      onTableChange={getHandlerTableChange(setTableData)}
      pagination={ paginationFactory({
        pageStartIndex: 1,
        sizePerPageList: [
          { text: '20', value: 20},
          { text: 'All', value: tableData.length },
        ]}) }
      defaultSorted={[{ dataField: "_id", order: "asc" }]}
      keyField="_id"
      data={tableData}
      columns={columns}
    >
    </BootstrapTable>
  );
}

const UserOrders = ({ userId }) => {
  const userOrders = useSelector(state => state.user.userOrders);
  const dispatch = useDispatch();

  const handleClickOnAction = (orderId) => () => {
    dispatch(actions.releaseUserOrder(userId, orderId))
  }

  const findPayout = (order) => {
    const isBitcoin = _.get(order, 'card.service') === 'bitcoin';

    return (!isBitcoin
      ? `${_.get(order, 'amount', 0)}`
      : `${Math.abs(_.get(order, 'btcAmount', 0))} BTC / ${Math.abs(_.get(order, 'usdAmount', 0))} `
    )
  }

  const orderTableEntities = () => {
    if(!userOrders.length) {
      return [];
    }

    return userOrders.map((order) => {
      return {
        _id: order._id,
        placementDate: order.createdAt,
        paymentDate: order.paidAt,
        orderStatus: order.status,
        verificationStatus: order.securityPassed === 'yes' ? 'Confirmed' : 'Pending manual verification from Surveytime staff',
        payout: `$${findPayout(order)}`,
        paymentMethod: order?.card?.service || '',
        paymentAccount: order?.user?.payPalEmail || order?.user?.coinbaseEmail || order?.user?.email || '',
        paymentImage: order?.card?.imageUrls?.['80w-326ppi'] || '',
        paymentDescription: order?.card?.name || '',
        redemptionCode: order.paidCardClaimCode || '',
        cardId: order?.card?.cardId,
        clickId: order?.click?._id || '',
        action: order.status === 'pending' ? (
          <button
            onClick={handleClickOnAction(order._id)}
            type="button"
            className="text-white bg-warning px-3 py-2 rounded btn btn-sm">
            Release Order
          </button>
        ) : ''
      }
    })
  }

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5 d-flex">
          User's Orders
          <Link to={`/orders/userId/${userId}`} target="_blank">
            <span className="ml-3">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Share.svg")}
            />
          </span>
          </Link>
        </h3>
        <OrdersTable entities={orderTableEntities()} />
      </CardContent>
    </Card>
  )
}

const OrderField = ({ color, title, description }) => {
  const svgClassName = `d-flex min-w-40px min-h-40px svg-icon svg-icon-${color} svg-icon-2x bg-light-${color} rounded align-items-center justify-content-center`;

  return (
    <div className="d-flex min-w-150px">
        <span className={svgClassName}>
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart3.svg")}
          />
        </span>
      <div className="d-flex justify-content-between flex-column ml-2">
        <div className="font-size-h4 font-weight-boldest">{title}</div>
        <div className="text-muted">{description}</div>
      </div>
    </div>
  )
}

const TotalOrders = () => {
  const userOrders = useSelector(state => state.user.userOrders);

  return (
    <Card>
      <CardContent className="border text-dark border-light-dark rounded">
        <h3 className="card-title font-weight-bolder text-dark mb-5">
          Total Orders
        </h3>
        <div className="pl-5 mb-3 font-size-h3 font-weight-boldest">{userOrders.length}</div>
        <div className="pl-20 pr-20">
          <div className="d-flex justify-content-between mb-12">
            <OrderField
              color="success"
              description="Paid"
              title={userOrders.length ? userOrders?.filter((order) => order.status === 'paid').length : 0}
            />
            <OrderField
              color="warning"
              description="Pending"
              title={userOrders.length ? userOrders?.filter((order) => order.status === 'pending').length : 0}
            />
          </div>
          <div className="d-flex justify-content-between mb-12">
            <OrderField
              color="info"
              description="Waiting Change"
              title={userOrders.length ? userOrders?.filter((order) => order.status === 'waiting-change').length : 0}
            />
            <OrderField
              color="danger"
              description="Rejected"
              title={userOrders.length ? userOrders?.filter((order) => order.status === 'rejected').length : 0}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const OrdersTab = ({ userId }) => {
  const userInformation = useSelector(state => state.user.userInformation);
  const dispatch = useDispatch();
  const userOrders = useSelector(state => state.user.userOrders);

  useEffect(() => {
    dispatch(actions.getUserOrders(userId));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if(!userInformation.user || !userOrders.length) {
    return null;
  }

  return (
    <Grid container spacing={1} className="flex-row-fluid">
      <Grid item xs={12} md={6}>
        <TotalOrders />
      </Grid>
      <Grid item xs={12} md={6}>
        <MonthChart data={userOrders} baseColor="success" className="border pb-2 border-light-dark rounded" />
      </Grid>
      <Grid item xs={12} md={12}>
        <UserOrders userId={userId} />
      </Grid>
    </Grid>
  )
};

export default OrdersTab;
