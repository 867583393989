const _ = require('lodash');

const commonDimensions = {
  userCountryCode: 'Country Code',
  userRegistrationPlatform: 'Registration Platform',
  userRegistrationOSVersion: 'Registration OS Version',
  userRegistrationPublisherId: 'Registration PublisherID',
  userAge: 'Age',
  userGender: 'Gender',
  userHasMobileApp: 'Has Mobile App',
  userLastProfilerQuestionId: 'Last Profiler QuestionID',
  userRegistrationDevice: 'Registration Device',
  userProfilerStatus: 'Profiler Status',
  userIsBanned: 'Banned Status',
  userBanReason: 'Banned Reason',
  userFunnelStatus: 'Funnel Status',
  userFraudScoreStatus: 'Fraud Score Status',
  userFraudScoreBucket: 'Fraud Score Bucket',
  userVeriffStatus: 'Veriff Status',
  userEmailVerified: 'Is Email Verified',
  userPhoneVerified: 'Is Phone Verified',

  clickPublisherId: 'Click PublisherID',
  clickSubId: 'Click SubId',
  clickSubId1: 'Click SubId1',
  clickSubId2: 'Click SubId2',
  clickSubId3: 'Click SubId3',
  clickPlatform: 'Click Platform',
  clickSurveyProvider: 'Click Survey Provider',
  clickSurveySubProvider: 'Click Survey Sub Provider',
  clickSurveyId: 'Click SurveyID',
  clickOrigin: 'Click Origin',
  clickSurveyCPIBucket: 'Survey CPI Bucket',
  clickProviderStatus: 'Click Provider Status',
  clickProviderTerm: 'Click Provider Term',

  orderRedeemMethod: 'Redeem Method',

  attributedPublisherId: 'Attributed PublisherID',

  orderStatus: 'Order Status',
  clickStatus: 'Click Status',
}

const dimensions = {
  createDate: 'Date',
  ...commonDimensions,
};

const eventsMeasures = {
  'user-registered': 'user: Registered',
  'user-first-paid': 'user: First Paid',
  'user-first-click': 'user: First Click',
  'user-first-lead': 'user: First Lead',
  'user-profiler-finished': 'user: Profiler Finished',
  'user-profiler-started': 'user: Profiler Started',
  'user-profiler-answer': 'user: Profiler Answer',
  'user-banned': 'user: Banned',
  'click-created-preliminary-failed': 'click: Created Preliminary Failed',
  'click-created-pending': 'click: Created Pending',
  'click-status-pending': 'click: Status Pending',
  'click-status-success': 'click: Status Success',
  'click-status-stopped': 'click: Status Stopped',
  'click-status-waiting-verifications': 'click: Status Waiting Verification',
  'click-status-dq': 'click: Status DQ',
  'click-status-reversal': 'click: Status Reversal',
  'click-status-loi-reversal': 'click: Status LOI Reversal',
  'click-survey-changed': 'click: Survey Changed',
  'order-created': 'order: Created',
  'order-status-created': 'order: Status Created',
  'order-status-rejected': 'order: Status Rejected',
  'order-status-waiting-change': 'order: Status WaitingChange',
  'order-status-on-hold': 'order: Status OnHold',
  'order-status-paid': 'order: Status Paid',
  'order-status-rejected-loi': 'order: Status Rejected LOI',
  'order-service-changed': 'order: Service Changed',
};

const measures = {
  clickBruttoEarned: 'click: Gross Revenue',
  clickNettoEarned: 'click: Net Revenue',
  mediaSpend: 'Media Spend',
  clicksCount: 'Clicks',
  clickUsers: 'Clicks: Users',
  ordersCount: 'Orders',
  orderUsers: 'Orders: Users',
};

const dimensionsDisabledForUI = [
];

const measuresDisabledForUI = [
  'click-status-dq',
  'click-status-pending',
];

const cohortDimensions = {
  ...commonDimensions,
  orderStatus: 'Order Status',
  clickStatus: 'Click Status',
};

const cohortMeasures = {
  cohortClicks: 'Cohort - Clicks',
  cohortOrders: 'Cohort - Orders',
  cohortRetention: 'Cohort - Retention',
  cohortNewConverters: 'Cohort - New Converter',
  cohortNettoEarned: 'Cohort - Min Net Revenue',
  cohortActualNettoEarned: 'Cohort - Actual Net Revenue',
  cohortBruttoEarned: 'Cohort - Gross Revenue',
  cohortMediaSpend: 'Cohort - Media Spend',
  cohortOrderPaid: 'Cohort - Order Payout',
  cohortROAS: 'Cohort - ROAS',
  cohortUsersCount: 'Users',
  cohortClicksCount: 'Clicks',
  cohortOrdersCount: 'Orders',
  cohortClicksUsersCount: 'Clicks - Users',
  cohortOrdersUsersCount: 'Orders - Users',
};

const cohortDimensionsDisabledForUI = [
];
const cohortMeasuresDisabledForUI = [
];
const displayableCohortDimensions = _.omit(cohortDimensions, cohortDimensionsDisabledForUI);
const displayableCohortMeasures = _.omit(cohortMeasures, cohortMeasuresDisabledForUI);

const mediaSpendDimensionsDisabledForUI = [
];
const mediaSpendMeasuresDisabledForUI = [
];

const mediaSpendMeasures = {
  externalROAS: 'External ROAS',
  externalMediaSpend: 'External Media Spend',
  externalGrossRevenue: 'Gross Revenue',
};

const mediaSpendDimensions = {
  createDate: 'Create Date',
  userCountryCode: 'Country Code',
  attributedPublisherId: 'Attributed PublisherID',
  clickPlatform: 'Click Platform',
};

const displayableMediaSpendDimensions = _.omit(mediaSpendDimensions, mediaSpendDimensionsDisabledForUI);
const displayableMediaSpendMeasures = _.omit(mediaSpendMeasures, mediaSpendMeasuresDisabledForUI);

const displayableDimensions = _.omit(dimensions, dimensionsDisabledForUI);
const displayableMeasures = _.omit(measures, measuresDisabledForUI);

const defaultSortBy = Object.keys(displayableDimensions)[0];

const sortDirections = {
  asc: 'asc',
  desc: 'desc',
};
const defaultSortDirection = Object.keys(sortDirections)[0];

const sortDirectionsToMongo = {
  [sortDirections.asc]: 1,
  [sortDirections.desc]: -1,
};

const DATE_FORMAT = 'dd MMM yyyy';

const DATETIME_FORMAT = 'MMM do yyyy, h:mm:ss a';

const veriffEventCodes = {
  STARTED: 7001,
  SUBMITTED: 7002,
};

const veriffNegativeDecisionCodes = {
  DECLINED: 9102,
  EXPIRED: 9104,
};

const veriffDecisionCodes = {
  ...veriffNegativeDecisionCodes,
  APPROVED: 9001,
  RESUBMITTED: 9103,
};

const veriffStatusByCodes = {
  9001: 'Approved',
  9103: 'Resubmitted',
  9102: 'Denied',
  9104: 'Expired',
  7001: 'Started',
  7002: 'Submitted',
  11122: 'Generated',
};

const veriffCodes = {
  CREATED: 111222, // our custom code, session creation response is codeless
  ...veriffEventCodes,
  ...veriffDecisionCodes,
};

const veriffReasons = {
  101: 'Physical document not used',
  102: 'Suspected document tampering',
  103: 'Person showing the document does not appear to match document photo',
  105: 'Suspicious behaviour',
  106: 'Known fraud',
  108: 'Velocity/abuse duplicated user',
  109: 'Velocity/abuse duplicated device',
  110: 'Velocity/abuse duplicated ID',
  509: 'Person showing the document does not match document photo',
  515: 'Attempted deceit, device screen used for face image',
  526: 'Attempted deceit, photos streamed',
  201: 'Video and/or photos missing',
  202: 'Face not clearly visible',
  203: 'Full document not visible',
  204: 'Poor image quality',
  205: 'Document damaged',
  206: 'Document type not supported',
  207: 'Document expired',
  605: 'Face missing from image',
  606: 'Face is not clearly visible',
  608: 'Document front missing',
  619: 'Document data not visible',
};

const veriffReasonsDisplayOrder = [
  105,
  207,
  206,

  101, 102, 103, 106, 108, 109, 110, 509, 515, 526, 201, 202, 203, 204, 205, 605, 606, 608, 619,
];

const templates = {
  NEW_SURVEY: {
    functionName: 'sendNewGoodSurvey',
    name: 'survey-router-new-good-survey-with-translation',
    subject: 'We have a new survey for you!',
  },
  SURVEY_LINK: {
    functionName: 'sendSurveyLink',
    name: 'survey-router-survey-link',
    subject: 'Survey for you!',
  },
  PENDING_PRIZE: {
    functionName: 'sendPendingPrize',
    name: 'survey-router-pending-prize-with-translation',
    subject: 'Redeem your prize!',
    title: 'Finish the order process and get your reward',
    button: 'Redeem your reward',
    description: 'You have a few more short steps to take in order to complete the order process and get your $[surveyRate] reward.',
  },
};

const userTabs = {
  userOverview: 'user-overview',
  profiler: 'profiler',
  accountInformation: 'account-information',
  targetedSurveys: 'targeted-surveys',
  clicksReport: 'clicks-report',
  ordersReport: 'orders-report',
  notifications: 'notifications',
  securityData: 'security-data',
  acquisition: 'acquisition',
  email: 'email',
  mobile: 'mobile',
  browser: 'browser',
  overview: 'overview',
  orders: 'orders',
  paypalLogs: 'paypal-logs',
  transactionsLog: 'transactions-log',
  nexmoLogs: 'nexmo-logs',
  veriffInformation: 'veriff-information',
  ipsReport: 'ips-report',
  postback: 'postback',
  touchPoint: 'touch-point',
  mobileAppApiLogs: 'mobile-app-api-logs',
};

const userSecurityMarks = [
  'copy_manually_banned',
  'double_verified_phone',
  'geo_ip_suspicious',
  'manually_banned',
  'non_matching_browser_language',
  'non_matching_country',
  'onesignal_country_suspicious',
  'paypal_check',
  'paypal_no_match',
  'profiler_match_80_same_access',
  'proxy',
  'proxy_range',
  'reversal_blocked',
  'same_paypal_account_email',
  'same_paypal_email',
  'screen_resolution',
  'storage_double_verified_phone',
  'storage_geo_ip_suspicious',
  'storage_manually_banned',
  'storage_non_matching_browser_language',
  'storage_non_matching_country',
  'storage_onesignal_country_suspicious',
  'storage_paypal_check',
  'storage_paypal_no_match',
  'storage_proxy',
  'storage_proxy_range',
  'storage_reversal_blocked',
  'storage_same_paypal_account_email',
  'storage_same_paypal_email',
  'storage_screen_resolution',
  'storage_state_no_match',
];

const fraudMarksExceptions = [
  'api_duplicated_users',
  'api_relevantid_mark',
  'storage_api_duplicated_users',
  'storage_copy_proxy_range',
  'storage_copy_reversal_blocked',
  'storage_copy_onesignal_country_suspicious',
  'storage_copy_same_paypal_account_email',
  'storage_copy_manually_banned',
];

const mandrilTemplate = {
  'survey-router-sign-up-survey-welcome-with-translation': false,
  'survey-router-signup-welcome-with-translation': false,
  'survey-router-signup-gh-welcome-with-translation': false,
  'survey-router-new-good-survey-with-translation': false,
  'survey-router-survey-link': false,
  'survey-router-user-reminder': false,
  'survey-router-email-change-with-translation': false,
  'survey-router-reset-password': false,
  'survey-router-new-password-with-translation': false,
  'survey-router-order-paid-with-translation': false,
  'survey-router-pending-prize-with-translation': false,
  'survey-router-order-paid-paypal-with-translation': false,
  'survey-router-order-rejected': false,
  'survey-router-resend-paypal-order-rejected': false,
  'survey-router-order-stopped': false,
  'survey-router-order-resent': false,
  'survey-router-order-pending': false,
  'survey-router-admin-notification': false,
  'survey-router-admin-new-publisher-created': false,
  'survey-router-general-email': false,
  'survey-router-pre-signup-publisher': false,
  'survey-router-signup-welcome-publisher': false,
  'survey-router-publisher-rejected': false,
  'id-verification-email': false,
};

const userMarks = {
  ACTIVE_CLICKS: 'active_clicks',
  MASS_ORDERING_WHITELIST: 'mass_ordering_whitelist',
  ACTIVE_COMPLETIONS: 'active_completions',
  RED_HERRING_FAILURE: 'red_herring_failure',
  PROXY: 'proxy',
  PROXY_RANGE: 'proxy_range',
  GEO_IP_SUSPICIOUS: 'geo_ip_suspicious',
  NON_MATCHING_COUNTRY: 'non_matching_country',
  REDEEMER: 'redeemer',
  REVERSAL_USER: 'reversal_user',
  REVERSAL_BLOCKED: 'reversal_blocked',
  SPEEDER: 'speeder',
  BOT_SUSPICIOUS: 'bot_suspicious',
  QUALITY_PARTICIPANT: 'quality_participant',
  FAKE_PHONE_NUMBER: 'fake_phone_number',
  SAME_PAYPAL_EMAIL: 'same_paypal_email',
  SAME_COINBASE_EMAIL: 'same_coinbase_email',
  SAME_PAYMENT_ACCOUNT_OF_RECENT_USER: 'same_payment_account_of_recent_user',
  SAME_PAYMENT_ACCOUNT_OF_BANNED_USER: 'same_payment_account_of_banned_user',
  DOUBLE_USER_REDEEMER: 'double_user_redeemer',
  DOUBLE_VERIFIED_PHONE: 'double_verified_phone',
  LANDLINE_PHONE_NUMBER: 'landline_phone_number',
  DUPLICATED_IP_4_USERS: 'duplicated_ip_4_users',
  USES_DESKTOP_VIEW_ON_MOBILE: 'uses_desktop_view_on_mobile',
  PHONE_BLACKLISTED_FOR_VERIFICATION: 'phone_blacklisted_for_verification',
  DONT_OPEN_GOOD_SURVEYS_EMAILS: 'Dont_open_good_surveys_emails',

  MANUALLY_BANNED: 'manually_banned',
  BANNED: 'banned',
  UNDER_16_BANNED: 'under_16',
  SAME_DOCUMENT_BANNED_USER: 'same_document_banned_user',
  SAME_DOCUMENT_RECENTLY_APPROVED: 'same_document_recently_approved',
  VERIFIED_AGE_TOO_OFF: 'verified_age_too_off',

  HIGH_REVERSAL_RATE: 'high_reversal_rate',
  VERIFF_REQUEST_EXPIRED: 'veriff_request_expired',
  STATE_NO_MATCH: 'state_no_match',
  PAYPAL_NO_MATCH: 'paypal_no_match',
  MATCHING_PAYPAL_COUNTRY: 'matching_paypal_country',
  HARD_BOUNCED_EMAIL: 'hard_bounced_email',
  PAYPAL_CHECK: 'paypal_check',
  SCREEN_RESOLUTION: 'screen_resolution',
  NON_MATCHING_BROWSER_LANGUAGE: 'non_matching_browser_language',
  USING_ANDROID_EMULATOR: 'using_android_emulator',

  // silent security
  SUSPICIOUS_USER_AGENT: 'suspicious_user_agent',
  SUSPICIOUS_NO_REFERRER_IN_PAGES: 'suspicious_no_referrer_in_pages',
  YAHOO_EMAIL: 'yahoo_email',

  USED_18_RANK: 'used_18_rank',

  SAME_PAYPAL_ACCOUNT_EMAIL: 'same_paypal_account_email',
  ONESIGNAL_COUNTRY_SUSPICIOUS: 'onesignal_country_suspicious',
  GHOST_COMPLETION_DETECTED: 'ghost_completion_detected',

  ISP_BLOCK: 'isp_block',

  COPY_MANUALLY_BANNED: 'copy_manually_banned',
  COPY_PROXY: 'copy_proxy',
  COPY_PROXY_RANGE: 'copy_proxy_range',
  COPY_NON_MATCHING_COUNTRY: 'copy_non_matching_country',
  COPY_FAKE_PHONE_NUMBER: 'copy_fake_phone_number',
  COPY_GEO_IP_SUSPICIOUS: 'copy_geo_ip_suspicious',
  COPY_SAME_PAYPAL_EMAIL: 'copy_same_paypal_email',
  COPY_SAME_COINBASE_EMAIL: 'copy_same_coinbase_email',
  COPY_DOUBLE_USER_REDEEMER: 'copy_double_user_redeemer',
  COPY_DOUBLE_VERIFIED_PHONE: 'copy_double_verified_phone',
  COPY_LANDLINE_PHONE_NUMBER: 'copy_landline_phone_number',
  COPY_DUPLICATED_IP_4_USERS: 'copy_duplicated_ip_4_users',
  COPY_STATE_NO_MATCH: 'copy_state_no_match',
  COPY_PAYPAL_NO_MATCH: 'copy_paypal_no_match',
  COPY_REVERSAL_BLOCKED: 'copy_reversal_blocked',
  COPY_ONESIGNAL_COUNTRY_SUSPICIOUS: 'copy_onesignal_country_suspicious',
  COPY_SAME_PAYPAL_ACCOUNT_EMAIL: 'copy_same_paypal_account_email',
  COPY_SCREEN_RESOLUTION: 'copy_screen_resolution',
  COPY_ISP_BLOCK: 'copy_isp_block',
  COPY_NON_MATCHING_BROWSER_LANGUAGE: 'copy_non_matching_browser_language',
  COPY_USING_ANDROID_EMULATOR: 'copy_using_android_emulator',
};

const decisionCodes = {
  APPROVED: 9001,
  DECLINED: 9102,
  RESUBMITTED: 9103,
  EXPIRED: 9104,
};

const fraudUserMarks = [
  userMarks.PROXY,
  userMarks.PROXY_RANGE,
  userMarks.NON_MATCHING_COUNTRY,
  userMarks.GEO_IP_SUSPICIOUS,
  userMarks.SAME_PAYPAL_EMAIL,
  userMarks.SAME_COINBASE_EMAIL,
  userMarks.SAME_PAYMENT_ACCOUNT_OF_RECENT_USER,
  userMarks.SAME_PAYMENT_ACCOUNT_OF_BANNED_USER,
  userMarks.DOUBLE_VERIFIED_PHONE,
  userMarks.MANUALLY_BANNED,
  userMarks.REVERSAL_BLOCKED,
  userMarks.SCREEN_RESOLUTION,
  userMarks.ONESIGNAL_COUNTRY_SUSPICIOUS,
  userMarks.SAME_PAYPAL_ACCOUNT_EMAIL,
  userMarks.NON_MATCHING_BROWSER_LANGUAGE,
  userMarks.USING_ANDROID_EMULATOR,
  userMarks.HIGH_REVERSAL_RATE,
  userMarks.GHOST_COMPLETION_DETECTED,
  userMarks.SAME_DOCUMENT_BANNED_USER,
  userMarks.SAME_DOCUMENT_RECENTLY_APPROVED,
  userMarks.VERIFIED_AGE_TOO_OFF,
  // userMarks.UNDIER_16_BANNED,
  // copies of marks
  // userMarks.COPY_MANUALLY_BANNED,
];

const churnIndications = [
  'fraud',
  'profilerNotStarted',
  'profilerNotFinished',
  'noClicks',
  'clicksNoLeads',
  'clicksLeadNoOrders',
  'clicksLeadPostbackNoOrders',
  'orderLess24h',
  'orderBetween14d24h',
  'orderMore14Days',
];

const amazonEmailStatuses = {
  Created: 'Created',
  Sent: 'Sent',
  Rejected: 'Rejected',
  Delivered: 'Delivered',
};

const clickStatus = {
  PENDING: 'pending',
  PRELIMINARY_FAILED: 'preliminaryFailed',
  SUCCESS: 'success',
  WAITING_VERIFICATION: 'waitingVerification',
  STOPPED: 'stopped',
  REVERSAL: 'reversal',
  DQ: 'dq',
  BANNED: 'banned',
  LOI_REVERSAL: 'loi_reversal',
};

const paymentMethodIcons = {
  paypal: '/media/svg/logos/paypal.svg',
  bitcoin: '/media/svg/logos/bitcoin.svg',
  amazon: '/media/svg/logos/amazon.svg',
  tangocard: '/media/svg/logos/tango-card-icon.png',
  wincube: '/media/svg/logos/wincube.svg',
  tremendous: '/media/svg/logos/tremendous.svg',
  voltage: '/media/logos/voltage.png',
};

const wincubeCardIcons = {
  G00000129952: '/media/svg/logos/GS25.svg',
  G00000130687: '/media/svg/logos/7elogosvg.svg',
  G00000127586: '/media/svg/logos/cu.svg',
  G00000130226: '/media/svg/logos/emart.svg',
};

const userOSIcons = {
  iOS: '/media/svg/logos/iOS.svg',
  AndroidOS: '/media/svg/logos/AndroidOS.svg',
  desktop: '/media/svg/logos/desktop.svg',
};

const surveysProviders = {
  FEDERATED: 'federated',
  TOLUNA: 'toluna',
  PRECISION: 'precision',
  RFG: 'rfg',
  SSI: 'ssi',
  CINT: 'cint',
  INNOVATE: 'innovate',
  PANELAND: 'paneland',
  MARKET_CUBE: 'marketCube',
  LIGHTSPEED: 'lightspeed',
  IPSOS: 'ipsos',
  PURE_SPECTRUM: 'pureSpectrum',
  SSI2: 'ssi2',
  BORDERLESS_ACCESS: 'borderlessAccess',
  PARADIGM: 'paradigm',
  QUALTRICS: 'qualtrics',
  GMO: 'gmo',
  MARKET_CUBE_2: 'marketCube2',
  GLOBAL_SURVEY: 'globalSurvey',
  PRODEGE: 'prodege',
  POLLFISH: 'pollfish',
  MORNING_CONSULT: 'morningConsult',
  SSI_REX: 'ssiRex',
  TOLUNA_IPES: 'tolunaIpes',
};

const questionsIdsByVerticals = {
  b2b: [
    3546, 59978, 63514, 67278, 82187, 107681, 110187, 99922419,
    99930295, 99931211, 99933172, 99933477, 99934797,
  ],
  car: [
    647, 648, 649, 650, 715, 717, 20613, 28975, 51660, 99920044, 99920165,
    99920298, 99920301, 99920302, 99920310, 99920311, 99920420, 99920422,
    99920423, 99920427, 99920544, 99920547, 99920550, 99920661, 99920767, 99920771,
    99920884, 99920984, 99921104, 99921107, 99921110, 99921220, 99921222, 99921223,
    99921225, 99921229, 99921231, 99921451, 99921452, 99921570, 99921572, 99921798,
    99921799, 99921800, 99922029, 99922033, 99922153, 99922154, 99922155, 99922156,
    99922157, 99922158, 99922159, 99922160, 99922161, 99922165, 99922298, 99922299,
    99922300, 99922301, 99922303, 99922304, 99922305, 99922306, 99922307, 99922308,
    99922309, 99922310, 99922423, 99922424, 99922425, 99922429, 99922642, 99922651,
    99922734, 99930435, 99932447,
  ],
  career: [
    643, 645, 646, 2189, 5729, 6751, 15297, 22467, 50105, 59215, 63513, 65774, 70071,
    70197, 70937, 72233, 74684, 74807, 74808, 78926, 79835, 82221, 87064, 88689, 91294,
    91295, 91298, 92494, 93519, 93520, 93521, 93522, 95125, 95184, 95795, 95806, 95807,
    95811, 97388, 97389, 97866, 99380, 100212, 100260, 105720, 107669, 109898, 110186,
    110995, 112068, 99920028, 99920030, 99920031, 99920032, 99920034, 99920150, 99920151,
    99920152, 99920153, 99920154, 99920157, 99920283, 99920284, 99920285, 99920286,
    99920288, 99920290, 99920293, 99920406, 99920407, 99920408, 99920411, 99920424,
    99920527, 99920528, 99920530, 99920532, 99920533, 99920534, 99920536, 99920537,
    99920644, 99920645, 99920647, 99920652, 99920752, 99920754, 99920756, 99920757,
    99920758, 99920759, 99920867, 99920868, 99920869, 99920968, 99920972, 99920973,
    99920975, 99921090, 99921091, 99921092, 99921093, 99921094, 99921095, 99921096,
    99921203, 99921206, 99921207, 99921208, 99921209, 99921210, 99921211, 99921324,
    99921325, 99921327, 99921329, 99921434, 99921553, 99921554, 99921555, 99921556,
    99921560, 99921781, 99921783, 99921784, 99921786, 99921787, 99921788, 99921902,
    99921909, 99922015, 99922017, 99922018, 99922021, 99922119, 99922137, 99922138,
    99922139, 99922140, 99922141, 99922142, 99922144, 99922146, 99922281, 99922284,
    99922285, 99922286, 99922287, 99922289, 99922291, 99922292, 99922406, 99922408,
    99922409, 99922410, 99922412, 99922413, 99922518, 99922519, 99922521, 99922525,
    99922745, 99930270, 99930281, 99930282, 99930296, 99930299, 99930301, 99930306,
    99930346, 99930358, 99930363, 99930366, 99930378, 99930381, 99930385, 99930408,
    99930409, 99930442, 99930521, 99930522, 99930525, 99930670, 99930697, 99930699,
    99930700, 99930726, 99930739, 99930769, 99930777, 99931249, 99931255, 99931259,
    99931716, 99932316, 99932869, 99933189, 99933537, 99933578, 99934084, 99934183,
    99934184, 99934418, 99934438, 99934488, 99934494, 99934496, 99934508, 99934511,
    99934552, 99934555, 99934575,
  ],
  children: [
    1077, 1089, 1249, 3708, 7064, 30226, 30319, 39117, 39122, 105221, 99915475, 99920004,
    99920005, 99920006, 99920131, 99920133, 99920134, 99920261, 99920262, 99920264, 99920386,
    99920390, 99920392, 99920507, 99920508, 99920509, 99920631, 99920632, 99920735, 99920740,
    99920742, 99920842, 99920854, 99920859, 99920953, 99920955, 99921070, 99921073, 99921074,
    99921185, 99921187, 99921188, 99921305, 99921421, 99921422, 99921425, 99921536, 99921538,
    99921764, 99921767, 99921772, 99921886, 99921892, 99922001, 99922112, 99922114, 99922120,
    99922236, 99922249, 99922251, 99922257, 99922382, 99922384, 99922397, 99922504, 99922505,
    99930290, 99930457, 99930490, 99931375, 99931583, 99934365, 99934520,
  ],
  consumer: [
    638, 720, 722, 723, 724, 726, 731, 732, 733, 734, 735, 737, 740, 742, 744, 747, 749, 750,
    754, 756, 757, 6378, 13594, 19620, 25640, 28289, 30282, 40520, 61079, 61195, 61264, 61295,
    65518, 68369, 68370, 69867, 70939, 70940, 70948, 72701, 74875, 78422, 80481, 84293, 95172,
    97023, 97590, 97877, 99386, 109888, 109909, 112942, 99915473, 99920037, 99920055, 99920067,
    99920159, 99920177, 99920181, 99920198, 99920224, 99920273, 99920292, 99920309, 99920314,
    99920315, 99920325, 99920337, 99920351, 99920357, 99920370, 99920413, 99920430, 99920437,
    99920444, 99920445, 99920447, 99920450, 99920454, 99920466, 99920470, 99920474, 99920477,
    99920541, 99920552, 99920559, 99920561, 99920562, 99920570, 99920577, 99920578, 99920584,
    99920593, 99920597, 99920693, 99920704, 99920762, 99920779, 99920782, 99920813, 99920815,
    99920874, 99920930, 99920996, 99921012, 99921024, 99921105, 99921116, 99921124, 99921131,
    99921142, 99921143, 99921150, 99921154, 99921157, 99921159, 99921213, 99921230, 99921232,
    99921233, 99921235, 99921244, 99921247, 99921253, 99921254, 99921255, 99921256, 99921257,
    99921262, 99921270, 99921272, 99921276, 99921277, 99921278, 99921282, 99921353, 99921581,
    99921582, 99921610, 99921626, 99921714, 99921719, 99921726, 99921730, 99921731, 99921790,
    99921807, 99921809, 99921813, 99921822, 99921835, 99921836, 99921837, 99921842, 99921848,
    99921865, 99921932, 99922080, 99922143, 99922163, 99922166, 99922167, 99922168, 99922169,
    99922170, 99922176, 99922177, 99922181, 99922184, 99922185, 99922186, 99922187, 99922192,
    99922194, 99922195, 99922199, 99922202, 99922203, 99922205, 99922206, 99922208, 99922211,
    99922213, 99922214, 99922215, 99922217, 99922219, 99922220, 99922265, 99922267, 99922268,
    99922270, 99922271, 99922273, 99922276, 99922277, 99922282, 99922290, 99922294, 99922302,
    99922311, 99922312, 99922313, 99922314, 99922315, 99922317, 99922319, 99922322, 99922323,
    99922324, 99922325, 99922326, 99922327, 99922328, 99922329, 99922330, 99922331, 99922332,
    99922333, 99922334, 99922335, 99922337, 99922338, 99922341, 99922342, 99922343, 99922344,
    99922345, 99922347, 99922349, 99922350, 99922352, 99922354, 99922355, 99922356, 99922357,
    99922359, 99922360, 99922387, 99922469, 99922563, 99922627, 99922652, 99922656, 99922683,
    99922684, 99922715, 99922716, 99922723, 99930297, 99930317, 99930438, 99930481, 99930498,
    99930532, 99930544, 99930545, 99930547, 99930556, 99930570, 99930747, 99930827, 99930867,
    99930868, 99930872, 99930946, 99930947, 99931154, 99931199, 99931369, 99931643, 99931724,
    99932394, 99932530, 99932634, 99932731, 99932784, 99932852, 99932877, 99932917, 99933123,
    99934169, 99934390, 99934400, 99935245, 99935271, 99935693, 99935759,
  ],
  download: [29226],
  education: [
    633, 48741, 64216, 70196, 99920025, 99920147, 99920279, 99920403, 99920524, 99920747,
    99920861, 99920966, 99921087, 99921200, 99921320, 99921435, 99921548, 99921782, 99922012,
    99922133, 99922134, 99922135, 99922278, 99922279, 99922283, 99922401, 99922516, 99930434,
    99930585, 99935847,
  ],
  finance: [
    99920022, 99920272, 99920394, 99920396, 99920513, 99920520, 99920745, 99921077, 99921193,
    99921194, 99921198, 99921546, 99922121, 99922123, 99922124, 99922125, 99922126, 99922127,
    99922128, 99922254, 99922255, 99922256, 99922258, 99922399, 99922673, 99935808,
  ],
  general: [
    42, 43, 47, 113, 377, 632, 640, 725, 1035, 1047, 8213, 8214, 23839, 32352, 32353, 40727,
    73198, 74682, 94535, 99915656, 99916659, 99920128, 99920276, 99920436, 99920560, 99920739,
    99920783, 99921119, 99921237, 99921545, 99921814, 99921884, 99922171, 99922243, 99922245,
    99922316, 99922372, 99922374, 99922622, 99922672, 99922678, 99922688, 99922731, 99930255,
    99930256, 99930257, 99930258, 99930283, 99930285, 99930285, 99930285, 99930286, 99930294,
    99930294, 99930294, 99930308, 99930310, 99930312, 99930318, 99930329, 99930364, 99930365,
    99930369, 99930372, 99930373, 99930374, 99930380, 99930397, 99930402, 99930423, 99930485,
    99930559, 99930836, 99930888, 99930891, 99931008, 99931012, 99931027, 99931248, 99931254,
    99931262, 99931325, 99931651, 99931699, 99931703, 99931916, 99932476, 99933025, 99933129,
    99934089, 99934091, 99934131, 99934335, 99934338, 99934724, 99934958, 99934959, 99935000,
    99935001, 999999000000001, 999999000000002,
  ],
  health: [
    759, 760, 761, 762, 764, 769, 3919, 3920, 17985, 47037, 81000, 105016, 99920362, 99920481,
    99920484, 99920827, 99921167, 99921284, 99921289, 99921399, 99921740, 99922223, 99922363,
    99922364, 99922366, 99922368, 99922369, 99922594, 99930441, 99933480,
  ],
  household: [
    641, 642, 2216, 99916638, 99920389, 99920852, 99921072, 99921182, 99921885, 99922247,
    99922617, 99922671, 99930266, 99930267, 99932862,
  ],
  income: [
    14785, 14887, 48740, 61076, 61190, 77711, 90431, 90434, 90436, 109908, 99920011, 99920135,
    99920139, 99920263, 99920267, 99920393, 99920395, 99920510, 99920522, 99920635, 99920743,
    99920855, 99920956, 99920958, 99921076, 99921078, 99921186, 99921190, 99921311, 99921427,
    99921541, 99921542, 99921768, 99921776, 99921893, 99922005, 99922006, 99922115, 99922122,
    99922250, 99922253, 99922394, 99922395, 99922510, 99922625, 99922793, 99922794, 99922797,
    99922799, 99922800, 99930347, 99930429, 99930586, 99933990,
  ],
  panel: [2204],
  pets: [
    639, 99920007, 99920265, 99920391, 99920512, 99921075, 99921189, 99921540, 99921769,
    99922116, 99922252,
  ],
  pii: [29228, 48962],
  postalCode: [
    45, 1008, 12340, 12344, 12348, 12351, 12352, 12354, 12355, 12356, 12357, 12359, 12365,
    12370, 20140, 79355, 79357, 79358, 79362, 79369,
  ],
  region: [
    96, 97, 101, 120, 122, 1015, 12394, 12404, 12407, 12413, 12414, 12415, 12416, 12419, 12422,
    12423, 12426, 12427, 12439, 12442, 12443, 12444, 12452, 12453, 12455, 15636, 79330, 79332,
    79348, 79354, 79378, 79392, 79397, 105013, 99920127, 99922500, 99922501, 99922616, 99922802,
    99930276, 99930307, 99930606, 99931000, 99931607, 99931731, 99932086, 99933871, 99935036,
  ],
  religion: [23415],
  sports: [730],
  technology: [87032],
  voter: [99922260, 99922261, 99922262, 99930613],
  voting: [634, 32350, 100638],
  webcam: [
    43501, 99920246, 99920616, 99920837, 99921176, 99921297, 99922232, 99922233, 99922376,
    99922379,
  ],
};

const surveysProvidersValues = _.values(surveysProviders);
const verticals = _.keys(questionsIdsByVerticals);

const appNameById = {
  '58ad4a45a4f9a31100d9fe7b': 'Offer Wall',
  '59c4e31c161786110039c09c': 'Gifthulk',
  '5b1e510dec226e25e1028b93': 'Surveytime',
};

const marksFromApi = {
  // marks from survey-api
  api_CR_all_countries_new: 'api_CR_all_countries_new',
  api_cr_and_ua: 'api_cr_and_ua',
  api_Cr_ID: 'api_Cr_ID',
  api_relevantid_mark: 'api_relevantid_mark',
  api_banned_users: 'api_banned_users',
  api_proxy_and_cr_new: 'api_proxy_and_cr_new',
  api_duplicated_users: 'api_duplicated_users',
  api_Cr_CN: 'api_Cr_CN',
  api_double_verified_phone: 'api_double_verified_phone',
  api_non_matching_phone: 'api_non_matching_phone',
  api_Cr_VN: 'api_Cr_VN',
  api_au_cr: 'api_au_cr',
  api_reversals: 'api_reversals',
  api_proxy: 'api_proxy',
  api_CR_VN_new: 'api_CR_VN_new',
};

const orderVerificationErrors = {
  PAYPAL_CHECK: 'paypal_check',
  PROXY: 'proxy',
  PROXY_RANGE: 'proxy_range',
  GEO_IP_SUSPICIOUS: 'geo_ip_suspicious',
  NON_MATCHING_COUNTRY: 'non_matching_country',
  REVERSAL_BLOCKED: 'reversal_blocked',
  FAKE_PHONE_NUMBER: 'fake_phone_number',
  SAME_PAYPAL_EMAIL: 'same_paypal_email',
  DOUBLE_USER_REDEEMER: 'double_user_redeemer',
  DOUBLE_VERIFIED_PHONE: 'double_verified_phone',
  DUPLICATED_IP_4_USERS: 'duplicated_ip_4_users',
  MANUALLY_BANNED: 'manually_banned',
  BANNED: 'banned',
  STATE_NO_MATCH: 'state_no_match',
  PAYPAL_NO_MATCH: 'paypal_no_match',
  SCREEN_RESOLUTION: 'screen_resolution',
  PROFILER_MATCH_80_SAME_ACCESS: 'profiler_match_80_same_access',
  ONESIGNAL_COUNTRY_SUSPICIOUS: 'onesignal_country_suspicious',
  SAME_PAYPAL_ACCOUNT_EMAIL: 'same_paypal_account_email',
  NON_MATCHING_BROWSER_LANGUAGE: 'non_matching_browser_language',
  USING_ANDROID_EMULATOR: 'using_android_emulator',

  STORAGE_PROFILER_MATCH_80_SAME_ACCESS: 'storage_profiler_match_80_same_access',

  ISP_BLOCK: 'isp_block',

  // copies
  COPY_BANNED: 'copy_banned',
  COPY_REVERSAL_BLOCKED: 'copy_reversal_blocked',
  COPY_MANUALLY_BANNED: 'copy_manually_banned',
  COPY_PROXY: 'copy_proxy',
  COPY_PROXY_RANGE: 'copy_proxy_range',
  COPY_NON_MATCHING_COUNTRY: 'copy_non_matching_country',
  COPY_FAKE_PHONE_NUMBER: 'copy_fake_phone_number',
  COPY_GEO_IP_SUSPICIOUS: 'copy_geo_ip_suspicious',
  COPY_SAME_PAYPAL_EMAIL: 'copy_same_paypal_email',
  COPY_DOUBLE_USER_REDEEMER: 'copy_double_user_redeemer',
  COPY_DOUBLE_VERIFIED_PHONE: 'copy_double_verified_phone',
  COPY_LANDLINE_PHONE_NUMBER: 'copy_landline_phone_number',
  COPY_DUPLICATED_IP_4_USERS: 'copy_duplicated_ip_4_users',
  COPY_STATE_NO_MATCH: 'copy_state_no_match',
  COPY_PAYPAL_NO_MATCH: 'copy_paypal_no_match',
  COPY_ONESIGNAL_COUNTRY_SUSPICIOUS: 'copy_onesignal_country_suspicious',
  COPY_SAME_PAYPAL_ACCOUNT_EMAIL: 'copy_same_paypal_account_email',
  COPY_ISP_BLOCK: 'copy_isp_block',
  COPY_NON_MATCHING_BROWSER_LANGUAGE: 'copy_non_matching_browser_language',
  COPY_USING_ANDROID_EMULATOR: 'copy_using_android_emulator',
  ...marksFromApi,
};

const setAllMark = {
  SET_ALL: 'set_all',
};

const clickFamousPlatform = {
  DESKTOP_WINDOWS: 'Desktop - Windows',
  DESKTOP_MAC: 'Desktop - Mac OS',
  MOBILE_WEB_ANDROID: 'Mobile Web - Android',
  MOBILE_WEB_IOS: 'Mobile Web - iOS',
  MOBILE_APP_ANDROID: 'Mobile App - android',
  MOBILE_APP_IOS: 'Mobile App - iOS',
};

const orderStatus = {
  CREATED: 'created',
  CHECKED: 'checked',
  REJECTED: 'rejected',
  PAID: 'paid',
  WAITING_CHANGE: 'waiting_change',
  ON_HOLD: 'on_hold',
  REJECTED_LOI: 'rejected_loi',
};

const userFamousPlatform = {
  DESKTOP_WINDOWS: 'Desktop - Windows',
  DESKTOP_MAC: 'Desktop - Mac OS',
  MOBILE_WEB_ANDROID: 'Mobile Web - Android',
  MOBILE_WEB_IOS: 'Mobile Web - iOS',
  MOBILE_APP_ANDROID: 'Mobile App - android',
  MOBILE_APP_IOS: 'Mobile App - ios',
};

const banReasons = {
  MANUALLY_BANNED: 'manually_banned',
  BANNED: 'banned',
  SAME_PAYMENT_ACCOUNT_OF_RECENT_USER: 'same_payment_account_of_recent_user',
  SAME_PAYMENT_ACCOUNT_OF_BANNED_USER: 'same_payment_account_of_banned_user',
  GHOST_COMPLETION_DETECTED: 'ghost_completion_detected',
  UNDER_16_BANNED: 'under_16_banned',
  SAME_DOCUMENT_BANNED_USER: 'same_document_banned_user',
  SAME_DOCUMENT_RECENTLY_APPROVED: 'same_document_recently_approved',
  VERIFIED_AGE_TOO_OFF: 'verified_age_too_off',
};

const translatorRoles = {
  translator: 'Translator',
  proofreader: 'Proofreader',
};

const projectStatuses = {
  CREATED: 'Created',
  IN_PROGRESS: 'In Progress',
  PENDING_APPROVAL: 'Pending Approval',
  VERIFIED: 'Verified',
};
const questionTypes = {
  CALCULATED_DUMMY: 'Calculated Dummy',
  CONDITIONAL_LIST_MULTI_PUNCH: 'Conditional List - Multi Punch',
  CONDITIONAL_LIST_SINGLE_PUNCH: 'Conditional List - Single Punch',
  DUMMY: 'Dummy',
  GRID: 'Grid',
  MULTI_PUNCH: 'Multi Punch',
  NUMERIC_OPEN_END: 'Numeric - Open-end',
  SINGLE_PUNCH: 'Single Punch',
  TEXT_OPEN_END: 'Text - Open-end',
};

const languagesWithCode = [
  {
    code: 'ab',
    name: 'Abkhaz',
  },
  {
    code: 'aa',
    name: 'Afar',
  },
  {
    code: 'af',
    name: 'Afrikaans',
  },
  {
    code: 'ak',
    name: 'Akan',
  },
  {
    code: 'sq',
    name: 'Albanian',
  },
  {
    code: 'am',
    name: 'Amharic',
  },
  {
    code: 'ar',
    name: 'Arabic',
  },
  {
    code: 'an',
    name: 'Aragonese',
  },
  {
    code: 'hy',
    name: 'Armenian',
  },
  {
    code: 'as',
    name: 'Assamese',
  },
  {
    code: 'av',
    name: 'Avaric',
  },
  {
    code: 'ae',
    name: 'Avestan',
  },
  {
    code: 'ay',
    name: 'Aymara',
  },
  {
    code: 'az',
    name: 'Azerbaijani',
  },
  {
    code: 'bm',
    name: 'Bambara',
  },
  {
    code: 'ba',
    name: 'Bashkir',
  },
  {
    code: 'eu',
    name: 'Basque',
  },
  {
    code: 'be',
    name: 'Belarusian',
  },
  {
    code: 'bn',
    name: 'Bengali; Bangla',
  },
  {
    code: 'bh',
    name: 'Bihari',
  },
  {
    code: 'bi',
    name: 'Bislama',
  },
  {
    code: 'bs',
    name: 'Bosnian',
  },
  {
    code: 'br',
    name: 'Breton',
  },
  {
    code: 'bg',
    name: 'Bulgarian',
  },
  {
    code: 'my',
    name: 'Burmese',
  },
  {
    code: 'ca',
    name: 'Catalan; Valencian',
  },
  {
    code: 'ch',
    name: 'Chamorro',
  },
  {
    code: 'ce',
    name: 'Chechen',
  },
  {
    code: 'ny',
    name: 'Chichewa; Chewa; Nyanja',
  },
  {
    code: 'zh',
    name: 'Chinese',
  },
  {
    code: 'cv',
    name: 'Chuvash',
  },
  {
    code: 'kw',
    name: 'Cornish',
  },
  {
    code: 'co',
    name: 'Corsican',
  },
  {
    code: 'cr',
    name: 'Cree',
  },
  {
    code: 'hr',
    name: 'Croatian',
  },
  {
    code: 'cs',
    name: 'Czech',
  },
  {
    code: 'da',
    name: 'Danish',
  },
  {
    code: 'dv',
    name: 'Divehi; Dhivehi; Maldivian;',
  },
  {
    code: 'nl',
    name: 'Dutch',
  },
  {
    code: 'dz',
    name: 'Dzongkha',
  },
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'eo',
    name: 'Esperanto',
  },
  {
    code: 'et',
    name: 'Estonian',
  },
  {
    code: 'ee',
    name: 'Ewe',
  },
  {
    code: 'fo',
    name: 'Faroese',
  },
  {
    code: 'fj',
    name: 'Fijian',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
  {
    code: 'fr',
    name: 'French',
  },
  {
    code: 'ff',
    name: 'Fula; Fulah; Pulaar; Pular',
  },
  {
    code: 'gl',
    name: 'Galician',
  },
  {
    code: 'ka',
    name: 'Georgian',
  },
  {
    code: 'de',
    name: 'German',
  },
  {
    code: 'el',
    name: 'Greek, Modern',
  },
  {
    code: 'gn',
    name: 'GuaranÃ­',
  },
  {
    code: 'gu',
    name: 'Gujarati',
  },
  {
    code: 'ht',
    name: 'Haitian; Haitian Creole',
  },
  {
    code: 'ha',
    name: 'Hausa',
  },
  {
    code: 'he',
    name: 'Hebrew (modern)',
  },
  {
    code: 'hz',
    name: 'Herero',
  },
  {
    code: 'hi',
    name: 'Hindi',
  },
  {
    code: 'ho',
    name: 'Hiri Motu',
  },
  {
    code: 'hu',
    name: 'Hungarian',
  },
  {
    code: 'ia',
    name: 'Interlingua',
  },
  {
    code: 'id',
    name: 'Indonesian',
  },
  {
    code: 'ie',
    name: 'Interlingue',
  },
  {
    code: 'ga',
    name: 'Irish',
  },
  {
    code: 'ig',
    name: 'Igbo',
  },
  {
    code: 'ik',
    name: 'Inupiaq',
  },
  {
    code: 'io',
    name: 'Ido',
  },
  {
    code: 'is',
    name: 'Icelandic',
  },
  {
    code: 'it',
    name: 'Italian',
  },
  {
    code: 'iu',
    name: 'Inuktitut',
  },
  {
    code: 'ja',
    name: 'Japanese',
  },
  {
    code: 'jv',
    name: 'Javanese',
  },
  {
    code: 'kl',
    name: 'Kalaallisut, Greenlandic',
  },
  {
    code: 'kn',
    name: 'Kannada',
  },
  {
    code: 'kr',
    name: 'Kanuri',
  },
  {
    code: 'ks',
    name: 'Kashmiri',
  },
  {
    code: 'kk',
    name: 'Kazakh',
  },
  {
    code: 'km',
    name: 'Khmer',
  },
  {
    code: 'ki',
    name: 'Kikuyu, Gikuyu',
  },
  {
    code: 'rw',
    name: 'Kinyarwanda',
  },
  {
    code: 'ky',
    name: 'Kyrgyz',
  },
  {
    code: 'kv',
    name: 'Komi',
  },
  {
    code: 'kg',
    name: 'Kongo',
  },
  {
    code: 'ko',
    name: 'Korean',
  },
  {
    code: 'ku',
    name: 'Kurdish',
  },
  {
    code: 'kj',
    name: 'Kwanyama, Kuanyama',
  },
  {
    code: 'la',
    name: 'Latin',
  },
  {
    code: 'lb',
    name: 'Luxembourgish, Letzeburgesch',
  },
  {
    code: 'lg',
    name: 'Ganda',
  },
  {
    code: 'li',
    name: 'Limburgish, Limburgan, Limburger',
  },
  {
    code: 'ln',
    name: 'Lingala',
  },
  {
    code: 'lo',
    name: 'Lao',
  },
  {
    code: 'lt',
    name: 'Lithuanian',
  },
  {
    code: 'lu',
    name: 'Luba-Katanga',
  },
  {
    code: 'lv',
    name: 'Latvian',
  },
  {
    code: 'gv',
    name: 'Manx',
  },
  {
    code: 'mk',
    name: 'Macedonian',
  },
  {
    code: 'mg',
    name: 'Malagasy',
  },
  {
    code: 'ms',
    name: 'Malay',
  },
  {
    code: 'ml',
    name: 'Malayalam',
  },
  {
    code: 'mt',
    name: 'Maltese',
  },
  {
    code: 'mi',
    name: 'MÄori',
  },
  {
    code: 'mr',
    name: 'Marathi (MarÄá¹­hÄ«)',
  },
  {
    code: 'mh',
    name: 'Marshallese',
  },
  {
    code: 'mn',
    name: 'Mongolian',
  },
  {
    code: 'na',
    name: 'Nauru',
  },
  {
    code: 'nv',
    name: 'Navajo, Navaho',
  },
  {
    code: 'nb',
    name: 'Norwegian BokmÃ¥l',
  },
  {
    code: 'nd',
    name: 'North Ndebele',
  },
  {
    code: 'ne',
    name: 'Nepali',
  },
  {
    code: 'ng',
    name: 'Ndonga',
  },
  {
    code: 'nn',
    name: 'Norwegian Nynorsk',
  },
  {
    code: 'no',
    name: 'Norwegian',
  },
  {
    code: 'ii',
    name: 'Nuosu',
  },
  {
    code: 'nr',
    name: 'South Ndebele',
  },
  {
    code: 'oc',
    name: 'Occitan',
  },
  {
    code: 'oj',
    name: 'Ojibwe, Ojibwa',
  },
  {
    code: 'cu',
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
  },
  {
    code: 'om',
    name: 'Oromo',
  },
  {
    code: 'or',
    name: 'Oriya',
  },
  {
    code: 'os',
    name: 'Ossetian, Ossetic',
  },
  {
    code: 'pa',
    name: 'Panjabi, Punjabi',
  },
  {
    code: 'pi',
    name: 'PÄli',
  },
  {
    code: 'fa',
    name: 'Persian (Farsi)',
  },
  {
    code: 'pl',
    name: 'Polish',
  },
  {
    code: 'ps',
    name: 'Pashto, Pushto',
  },
  {
    code: 'pt',
    name: 'Portuguese',
  },
  {
    code: 'qu',
    name: 'Quechua',
  },
  {
    code: 'rm',
    name: 'Romansh',
  },
  {
    code: 'rn',
    name: 'Kirundi',
  },
  {
    code: 'ro',
    name: 'Romanian, [])',
  },
  {
    code: 'ru',
    name: 'Russian',
  },
  {
    code: 'sa',
    name: 'Sanskrit (Saá¹ská¹›ta)',
  },
  {
    code: 'sc',
    name: 'Sardinian',
  },
  {
    code: 'sd',
    name: 'Sindhi',
  },
  {
    code: 'se',
    name: 'Northern Sami',
  },
  {
    code: 'sm',
    name: 'Samoan',
  },
  {
    code: 'sg',
    name: 'Sango',
  },
  {
    code: 'sr',
    name: 'Serbian',
  },
  {
    code: 'gd',
    name: 'Scottish Gaelic; Gaelic',
  },
  {
    code: 'sn',
    name: 'Shona',
  },
  {
    code: 'si',
    name: 'Sinhala, Sinhalese',
  },
  {
    code: 'sk',
    name: 'Slovak',
  },
  {
    code: 'sl',
    name: 'Slovene',
  },
  {
    code: 'so',
    name: 'Somali',
  },
  {
    code: 'st',
    name: 'Southern Sotho',
  },
  {
    code: 'es',
    name: 'Spanish; Castilian',
  },
  {
    code: 'su',
    name: 'Sundanese',
  },
  {
    code: 'sw',
    name: 'Swahili',
  },
  {
    code: 'ss',
    name: 'Swati',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
  {
    code: 'ta',
    name: 'Tamil',
  },
  {
    code: 'te',
    name: 'Telugu',
  },
  {
    code: 'tg',
    name: 'Tajik',
  },
  {
    code: 'th',
    name: 'Thai',
  },
  {
    code: 'ti',
    name: 'Tigrinya',
  },
  {
    code: 'bo',
    name: 'Tibetan Standard, Tibetan, Central',
  },
  {
    code: 'tk',
    name: 'Turkmen',
  },
  {
    code: 'tl',
    name: 'Tagalog',
  },
  {
    code: 'tn',
    name: 'Tswana',
  },
  {
    code: 'to',
    name: 'Tonga (Tonga Islands)',
  },
  {
    code: 'tr',
    name: 'Turkish',
  },
  {
    code: 'ts',
    name: 'Tsonga',
  },
  {
    code: 'tt',
    name: 'Tatar',
  },
  {
    code: 'tw',
    name: 'Twi',
  },
  {
    code: 'ty',
    name: 'Tahitian',
  },
  {
    code: 'ug',
    name: 'Uyghur, Uighur',
  },
  {
    code: 'uk',
    name: 'Ukrainian',
  },
  {
    code: 'ur',
    name: 'Urdu',
  },
  {
    code: 'uz',
    name: 'Uzbek',
  },
  {
    code: 've',
    name: 'Venda',
  },
  {
    code: 'vi',
    name: 'Vietnamese',
  },
  {
    code: 'vo',
    name: 'VolapÃ¼k',
  },
  {
    code: 'wa',
    name: 'Walloon',
  },
  {
    code: 'cy',
    name: 'Welsh',
  },
  {
    code: 'wo',
    name: 'Wolof',
  },
  {
    code: 'fy',
    name: 'Western Frisian',
  },
  {
    code: 'xh',
    name: 'Xhosa',
  },
  {
    code: 'yi',
    name: 'Yiddish',
  },
  {
    code: 'yo',
    name: 'Yoruba',
  },
  {
    code: 'za',
    name: 'Zhuang, Chuang',
  },
  {
    code: 'zu',
    name: 'Zulu',
  },
];

const surveyFeedbackOptions = {
  0: 'Technical issue',
  1: 'Sensitive topic',
  2: 'Too long',
  3: 'Boring',
  4: 'Other',
};

const federatedOrLucidDQReasonsByNumber = {
  '-6': 'Pre-Client Intermediary Page Drop Off',
  '-5': 'Failure in the Post Answer Behavior',
  '-1': 'Failure to Load the Lucid Marketplace',
  1: 'Currently in Screener or Drop',
  3: 'Respondent Sent to the Client Survey',
  21: 'Industry Lockout',
  23: 'Standard Qualification',
  24: 'Custom Qualification',
  120: 'Pre-Client Survey Opt Out',
  122: 'Return to Marketplace Opt Out',
  123: 'Max Client Survey Entries',
  124: 'Max Time in Router',
  125: 'Max Time in Router Warning Opt Out',
  126: 'Max Answer Limit',
  30: 'Max Answer Limit',
  31: 'RelevantID Duplicate',
  32: 'Invalid Traffic',
  35: 'Supplier PID Duplicate',
  36: 'Cookie Duplicate',
  37: 'GEO IP Mismatch',
  38: 'RelevantID** Fraud Profile',
  131: 'Supplier Encryption Failure',
  132: 'Blocked PID',
  133: 'Blocked IP',
  134: 'Max Completes per Day Terminate',
  138: 'Survey Group Cookie Duplicate',
  139: 'Survey Group Supplier PID Duplicate',
  230: 'Survey Group Unique IP',
  234: 'Blocked Country IP',
  236: 'No Privacy Consent',
  237: 'Minimum Age',
  238: 'Found on Deny List',
  40: 'Quota Full',
  41: 'Supplier Allocation',
  42: 'Survey Closed for Entry',
  50: 'CPI Below Supplier’s Rate Card',
  98: 'End of Router',
};

const innovateDQReasonsByText = {
  'Selected threat potential score at joblevel not allow the survey': 'Device-level quality/fraud score for respondent did not meet threshold for study',
  'OE Validation': 'The quality of a response to an open end quality question did not meet the standard',
  'Unique IP': 'Respondent with same IP address has already participated on this survey',
  'Unique PID': 'Respondent with same PID has already participated on this survey',
  'Duplicated to token {token} and Group {groupID}': 'Respondent with same token/fingerprint has already participated on this survey',
  'Duplicate Due to Multi Groups: Token {token} and Group {groupID}': 'Respondent with token/fingerprint has participated on deduped survey',
  'User has attended {count} survey in 5 range': 'Respondent has participated in more surveys than allowed during a given time period',
  PII_OPT: 'Respondent chose not to provide PII information',
  Recaptcha: 'Respondent failed the recaptcha check',
  'URL Manipulation - Multiple Tries': 'Respondent attempted to manipulate the survey experience',
  'Quota closed': 'Quota that respondent qualifies for is no longer available',
  'CleanID Timeout Error': 'Fingerprinting attempt timed out',
  'CleanID Error': 'Error when fingerprinting respondent',
  'RED_HERRING_{id}': 'Respondent did not pass the Red Herring question for the survey',
  'INCOME / API_VERSIONS_{id} / Targeting Question Key': 'Respondent did not pass the specific targeting question for the survey',
  GEOIP: 'The Respondent IP address did not resolve to the country of the Survey',
  'Group NA': 'The Group/Survey was no longer available / live',
  'Job NA': 'The Job (Parent of Group/Survey) was no longer available / live',
  'Supplier NA': 'The Supplier was no longer available to participate on the Group/Survey',
  'This survey Country mismatch': 'The Respondent country did not match to the country of the Survey',
  DeviceType: 'The Respondent device did not match to the device of the Survey',
};

const MCDQReasonsByNumber = {
  51: 'Drop Out on landing page',
  58: 'Drop Out on qualifications',
  65: 'Client – entry encryption error',
  80: 'Linkedin – drop out on login',
  97: 'Prescreener start exception',
  98: 'Client – qualification error',
  40: 'Reconciled – complete',
  41: 'Reconciled – already a complete',
  48: 'Complete – not reconciled',
  69: 'Complete – supplier reservation',
  49: 'Client – overquota',
  39: 'Reconciled – terminate',
  50: 'Terminate – client side logic',
  83: 'Client redirect – internal failure',
  16: 'Client redirect – SHA-1 mismatch',
  26: 'Terminate – Min LOI logic',
  27: 'Terminate – Max LOI logic',
  52: 'Security terminate – client side logic',
  68: 'Client redirect – secret value missing',
  77: 'Drop Out before client entry',
  78: 'Not live survey completion',
  81: 'Client redirect – S2S not fired',
  84: 'Security Terminate – Speeder',
  1: 'Duplicate – not a unique PID',
  4: 'GeoIP – wrong country',
  7: 'Duplicate – not a unique IP',
  8: 'Duplicate – survey group',
  10: 'MaxMind – country mismatch',
  11: 'MaxMind – anonymous proxy detected',
  12: 'MaxMind – high fraud score',
  14: 'MaxMind – high risk score',
  18: 'Supplier entry – encryption failure',
  22: 'Trap question failure',
  38: 'Terminate – quality check on multi-punch',
  42: 'RelevantID – duplicate attempt',
  43: 'RelevantID – fraud profile score too high',
  44: 'RelevantID – wrong country',
  55: 'MaxMind – IP blacklisted',
  57: 'GeoIP – State check, US only',
  60: 'Duplicate – survey group unique IP',
  61: 'Duplicate – survey group unique SID',
  62: 'Duplicate – survey group RelevantID dupe',
  67: 'Bot detected',
  72: 'Duplicate – device user ID check',
  73: 'Duplicate – survey group Device User ID dupe',
  82: 'Known fraudster',
  85: 'Sample Chain – fraudster',
  86: 'Sample Chain – wrong country',
  87: 'Sample Chain – duplicate',
  89: 'Sample Chain – survey group duplicate',
  90: 'Duplicate – client logic',
  99: 'Blocked user',
  28: 'Terminate – pre-screener',
  35: 'Terminate – survey does not allow desktop devices',
  36: 'Terminate – survey does not allow mobile devices',
  37: 'Recontact – terminate',
  47: 'Terminate – demographic qualifications',
  56: 'Terminate – survey does not allow tablet devices',
  64: 'Terminate – custom demographic qualifications',
  71: 'Terminate – blocked IE browser version',
  74: 'Terminate – inconsistent age',
  75: 'Terminate – inconsistent gender',
  76: 'Terminate – inconsistent zip',
  79: 'LinkedIn – failed to login',
  88: 'Terminate – demo terminate on advanced logic',
  9: 'Overquota – supplier not allocated to the survey',
  29: 'Overquota – Survey closed',
  31: 'Overquota – financial termination',
  33: 'Overquota – Unique Link error',
  46: 'Sample Cube – overquota',
  63: 'Overquota – exceeded supplier allocation',
  70: 'Client – authentication error',
  94: 'Sample Chain – cross panel deduplication',
  54: 'Client redirect – drop out / in progress',
  59: 'OnePlus – no survey available.',
  34: 'Partial Complete – SOI',
};

const pureSpectrumDQReasonsByNumber = {
  11: 'PS Drop',
  12: 'PS Quota Full',
  13: 'PS Termination PS Termination Core',
  14: 'PS Side In Progress',
  15: 'PS Quality',
  16: 'Buyer In Progress',
  17: 'Buyer Quota Full',
  18: 'Buyer Termination',
  19: 'Buyer Drop',
  20: 'Buyer Quality Termination',
  21: 'Complete',
  22: 'PS Survey Closed Termination',
  23: 'PS Survey Paused Termination',
  24: 'PS Unopened Quota Term',
  25: 'PS Supplier Allocation Full',
  26: 'PS Past Participation Fail',
  27: 'PS Supplier Quota Allocation Full',
  28: 'PS Invalid Survey',
  29: 'PS LOI Threshold Failure',
  30: 'Buyer Security (De-Dupe)',
  31: 'Buyer Hash Failure',
  32: 'PS Grouping Termination',
  33: 'Buyer Reconcilliation Reject',
  34: 'PS Temp Exclusion',
  35: 'PS No matched quotas',
  36: 'PS Max IP Throttling Termination',
  37: 'PS Quota Throttling Termination',
  38: 'PS PSID Geo Termination',
  39: 'PS TrueSample Fail',
  40: 'PS GeoIP Fail',
  41: 'PS Bot Fail',
  42: 'PS BlackList Fail',
  43: 'PS Anonymous Fail',
  44: 'PS Include Fail',
  45: 'PS Termination Extended',
  46: 'PS Termination Custom',
  47: 'PS Quota Full Extended',
  48: 'PS Quota Full Custom',
  49: 'PS Include Fail',
  50: 'PS Exclude Fail',
  51: 'Invalid Supplier',
  52: 'PSID Service Fail',
  55: 'PS Unique Link Termination',
  56: 'Unauthorized Augment',
  57: 'PS Supplier Quota Full',
  58: 'PS Supplier Quota Throttling Termination',
  59: 'Buyer Config Error',
  60: 'PS_Js_Fail',
  63: 'PS_Blacklist_Data_Quality',
  64: 'PS_Blacklist_Data_Quality_2',
  65: 'PS_Rvid_IPStatus_Fail',
  67: 'PS_SC_Fraudster_Fail',
  68: 'PS_SC_Threat_Fail',
  69: 'PS_TC_Termination',
  70: 'PS_DF_DUPE',
};

const SSIDQReasonsByValue = {
  t: 'Terminate',
  oq: 'Overquota',
  st: 'Security Terminate',
};

const SSI2DQReasonsByValue = {
  2: 'Dropout',
  3: 'Prescreeners exhaustion',
  5: 'Survey Complete',
  6: 'Survey Overquota',
  7: 'Survey Screenout',
  8: 'Internal Error',
  9: 'Missing Entry URL Parameters',
  10: 'Invalid Hash Code',
  11: 'Project Not Launched',
  12: 'Under Age User',
  13: 'User Already Completed Survey',
  14: 'User Banned',
  15: 'IP Banned',
  16: 'ISP Banned',
  21: 'Doesn’t Match Survey',
  24: 'User’s country and lineitem country do not match',
  25: 'User is not registered',
  26: 'User Refused: Quality Fail',
  27: 'User Rejected: Survey Quality Fail',
  28: 'Prescreener Fail (pending update to break out further)',
  29: 'Unknown error',
  30: 'Duplicate Session',
  31: 'Blacklisted',
};

const rewardsTypes = ['paypal', 'amazon', 'bitcoin', 'tangocard', 'wincube', 'tremendous', 'voltage'];

const rewardsCardStatus = ['Available', 'Unavailable', 'Disabled'];

const cardsServices = {
  AMAZON: 'amazon',
  TANGOCARD: 'tangocard',
  PAYPAL: 'paypal',
  BITCOIN: 'bitcoin',
  WINCUBE: 'wincube',
  TREMENDOUS: 'tremendous',
  VOLTAGE: 'voltage',
};

const rewardsUpdatedFields = [
  'blocked', 'featured', 'countryListPredicate', 'countryList', 'price', 'minPrice', 'maxPrice', 'backgroundColor',
  'ribbonText', 'redeemCardLogo', 'rewardsTabLogo', 'payouts', 'name', 'webRewardsLogo', 'borderColor',
  'hoveredBackgroundColor', 'generateBarcode', 'emailLogo', 'whereIsRedeemableKey', 'howToGetPaymentKey',
  'termsKey', 'howToRedeemEmailKey', 'expirationDate', 'ribbonTextKey',
];

const amazonCardsStatuses = {
  REDEEMED: 'redeemed',
  NOT_REDEEMED: 'notRedeemed',
};

const amazonCardsAmounts = [1, 1.25, 1.75];

const defaultCurrencyRate = {
  AUD: 1.45,
  USD: 1,
  CAD: 1.3,
  CNY: 1.7,
  JPY: 110,
  GBP: 0.8,
  EUR: 0.83,
  INR: 50,
  PLN: 4.17,
};

const countriesWithCode = [
  {
    name: 'Afghanistan',
    code: 'AF',
    timezone: 'Afghanistan Standard Time',
    utc: 'UTC+04:30',
    mobileCode: '+93',
  },
  {
    name: 'Aland Islands',
    code: 'AX',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+358-18',
  },
  {
    name: 'Albania',
    code: 'AL',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+355',
  },
  {
    name: 'Algeria',
    code: 'DZ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+213',
  },
  {
    name: 'American Samoa',
    code: 'AS',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+1-684',
  },
  {
    name: 'Andorra',
    code: 'AD',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+376',
  },
  {
    name: 'Angola',
    code: 'AO',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+244',
  },
  {
    name: 'Anguilla',
    code: 'AI',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-264',
  },
  {
    name: 'Antarctica',
    code: 'AQ',
    timezone: 'Pacific SA Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-268',
  },
  {
    name: 'Argentina',
    code: 'AR',
    timezone: 'Argentina Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+54',
  },
  {
    name: 'Armenia',
    code: 'AM',
    timezone: 'Caucasus Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+374',
  },
  {
    name: 'Aruba',
    code: 'AW',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+297',
  },
  {
    name: 'Australia',
    code: 'AU',
    timezone: 'AUS Eastern Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+61',
  },
  {
    name: 'Austria',
    code: 'AT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+43',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    timezone: 'Azerbaijan Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+994',
  },
  {
    name: 'Bahamas',
    code: 'BS',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-242',
  },
  {
    name: 'Bahrain',
    code: 'BH',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+973',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    timezone: 'Bangladesh Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+880',
  },
  {
    name: 'Barbados',
    code: 'BB',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-246',
  },
  {
    name: 'Belarus',
    code: 'BY',
    timezone: 'Belarus Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+375',
  },
  {
    name: 'Belgium',
    code: 'BE',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+32',
  },
  {
    name: 'Belize',
    code: 'BZ',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+501',
  },
  {
    name: 'Benin',
    code: 'BJ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+229',
  },
  {
    name: 'Bermuda',
    code: 'BM',
    timezone: 'Atlantic Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-441',
  },
  {
    name: 'Bhutan',
    code: 'BT',
    timezone: 'Bangladesh Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+975',
  },
  {
    name: 'Bolivia',
    code: 'BO',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+591',
  },
  {
    name: 'Bonaire',
    code: 'BQ',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+387',
  },
  {
    name: 'Botswana',
    code: 'BW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+267',
  },
  {
    name: 'Bouvet Island',
    code: 'BV',
    timezone: 'UTC',
    utc: 'UTC',
    mobileCode: '+',
  },
  {
    name: 'Brazil',
    code: 'BR',
    timezone: 'E. South America Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+55',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+246',
  },
  {
    name: 'Brunei',
    code: 'BN',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+673',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+359',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+226',
  },
  {
    name: 'Burundi',
    code: 'BI',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+257',
  },
  {
    name: 'Cabo Verde',
    code: 'CV',
    timezone: 'Cape Verde Standard Time',
    utc: 'UTC-01:00',
    mobileCode: '+238',
  },
  {
    name: 'Cambodia',
    code: 'KH',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+855',
  },
  {
    name: 'Cameroon',
    code: 'CM',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+237',
  },
  {
    name: 'Canada',
    code: 'CA',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-345',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+236',
  },
  {
    name: 'Chad',
    code: 'TD',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+235',
  },
  {
    name: 'Chile',
    code: 'CL',
    timezone: 'Pacific SA Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+56',
  },
  {
    name: 'China',
    code: 'CN',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+86',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+61',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    timezone: 'Myanmar Standard Time',
    utc: 'UTC+06:30',
    mobileCode: '+61',
  },
  {
    name: 'Colombia',
    code: 'CO',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+57',
  },
  {
    name: 'Comoros',
    code: 'KM',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+269',
  },
  {
    name: 'Congo',
    code: 'CG',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+242',
  },
  {
    name: 'Congo (DRC)',
    code: 'CD',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+243',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    timezone: 'Hawaiian Standard Time',
    utc: 'UTC-10:00',
    mobileCode: '+682',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+506',
  },
  {
    name: "Côte d'Ivoire",
    code: 'CI',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+225',
  },
  {
    name: 'Croatia',
    code: 'HR',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+385',
  },
  {
    name: 'Cuba',
    code: 'CU',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+53',
  },
  {
    name: 'Curaçao',
    code: 'CW',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599',
  },
  {
    name: 'Cyprus',
    code: 'CY',
    timezone: 'E. Europe Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+357',
  },
  {
    name: 'Czech Republic',
    code: 'CZ',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+420',
  },
  {
    name: 'Democratic Republic of Timor-Leste',
    code: 'TL',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+670',
  },
  {
    name: 'Denmark',
    code: 'DK',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+45',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+253',
  },
  {
    name: 'Dominica',
    code: 'DM',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-767',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-809 and 1-829',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+593',
  },
  {
    name: 'Egypt',
    code: 'EG',
    timezone: 'Egypt Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+20',
  },
  {
    name: 'El Salvador',
    code: 'SV',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+503',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+240',
  },
  {
    name: 'Eritrea',
    code: 'ER',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+291',
  },
  {
    name: 'Estonia',
    code: 'EE',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+372',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+251',
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    code: 'FK',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+500',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+298',
  },
  {
    name: 'Fiji Islands',
    code: 'FJ',
    timezone: 'Fiji Standard Time',
    utc: 'UTC+12:00',
    mobileCode: '+679',
  },
  {
    name: 'Finland',
    code: 'FI',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+358',
  },
  {
    name: 'France',
    code: 'FR',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+33',
  },
  {
    name: 'French Guiana',
    code: 'GF',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+594',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    timezone: 'Hawaiian Standard Time',
    utc: 'UTC-10:00',
    mobileCode: '+689',
  },
  {
    name: 'French Southern and Antarctic Lands',
    code: 'TF',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+',
  },
  {
    name: 'Gabon',
    code: 'GA',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+241',
  },
  {
    name: 'Gambia',
    code: 'GM',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+220',
  },
  {
    name: 'Georgia',
    code: 'GE',
    timezone: 'Georgian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+995',
  },
  {
    name: 'Germany',
    code: 'DE',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+49',
  },
  {
    name: 'Ghana',
    code: 'GH',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+233',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+350',
  },
  {
    name: 'Greece',
    code: 'GR',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+30',
  },
  {
    name: 'Greenland',
    code: 'GL',
    timezone: 'Greenland Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+299',
  },
  {
    name: 'Grenada',
    code: 'GD',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-473',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590',
  },
  {
    name: 'Guam',
    code: 'GU',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+1-671',
  },
  {
    name: 'Guatemala',
    code: 'GT',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+502',
  },
  {
    name: 'Guernsey',
    code: 'GG',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1481',
  },
  {
    name: 'Guinea',
    code: 'GN',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+224',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+245',
  },
  {
    name: 'Guyana',
    code: 'GY',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+592',
  },
  {
    name: 'Haiti',
    code: 'HT',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+509',
  },
  {
    name: 'Heard Island and McDonald Islands',
    code: 'HM',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+ ',
  },
  {
    name: 'Honduras',
    code: 'HN',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+504',
  },
  {
    name: 'Hong Kong SAR',
    code: 'HK',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+852',
  },
  {
    name: 'Hungary',
    code: 'HU',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+36',
  },
  {
    name: 'Iceland',
    code: 'IS',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+354',
  },
  {
    name: 'India',
    code: 'IN',
    timezone: 'India Standard Time',
    utc: 'UTC+05:30',
    mobileCode: '+91',
  },
  {
    name: 'Indonesia',
    code: 'ID',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+62',
  },
  {
    name: 'Iran',
    code: 'IR',
    timezone: 'Iran Standard Time',
    utc: 'UTC+03:30',
    mobileCode: '+98',
  },
  {
    name: 'Iraq',
    code: 'IQ',
    timezone: 'Arabic Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+964',
  },
  {
    name: 'Ireland',
    code: 'IE',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+353',
  },
  {
    name: 'Israel',
    code: 'IL',
    timezone: 'Israel Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+972',
  },
  {
    name: 'Italy',
    code: 'IT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+39',
  },
  {
    name: 'Jamaica',
    code: 'JM',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-876',
  },
  {
    name: 'Jan Mayen',
    code: 'SJ',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+47',
  },
  {
    name: 'Japan',
    code: 'JP',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+81',
  },
  {
    name: 'Jersey',
    code: 'JE',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1534',
  },
  {
    name: 'Jordan',
    code: 'JO',
    timezone: 'Jordan Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+962',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+7',
  },
  {
    name: 'Kenya',
    code: 'KE',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+254',
  },
  {
    name: 'Kiribati',
    code: 'KI',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+686',
  },
  {
    name: 'Korea',
    code: 'KR',
    timezone: 'Korea Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+82',
  },
  {
    name: 'Kosovo',
    code: 'XK',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+',
  },
  {
    name: 'Kuwait',
    code: 'KW',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+965',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    timezone: 'Central Asia Standard Time',
    utc: 'UTC+06:00',
    mobileCode: '+996',
  },
  {
    name: 'Laos',
    code: 'LA',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+856',
  },
  {
    name: 'Latvia',
    code: 'LV',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+371',
  },
  {
    name: 'Lebanon',
    code: 'LB',
    timezone: 'Middle East Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+961',
  },
  {
    name: 'Lesotho',
    code: 'LS',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+266',
  },
  {
    name: 'Liberia',
    code: 'LR',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+231',
  },
  {
    name: 'Libya',
    code: 'LY',
    timezone: 'E. Europe Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+218',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+423',
  },
  {
    name: 'Lithuania',
    code: 'LT',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+370',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+352',
  },
  {
    name: 'Macao SAR',
    code: 'MO',
    timezone: 'China Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+853',
  },
  {
    name: 'Macedonia, Former Yugoslav Republic of',
    code: 'MK',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+389',
  },
  {
    name: 'Madagascar',
    code: 'MG',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+261',
  },
  {
    name: 'Malawi',
    code: 'MW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+265',
  },
  {
    name: 'Malaysia',
    code: 'MY',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+60',
  },
  {
    name: 'Maldives',
    code: 'MV',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+960',
  },
  {
    name: 'Mali',
    code: 'ML',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+223',
  },
  {
    name: 'Malta',
    code: 'MT',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+356',
  },
  {
    name: 'Man, Isle of',
    code: 'IM',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44-1624',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+692',
  },
  {
    name: 'Martinique',
    code: 'MQ',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+596',
  },
  {
    name: 'Mauritania',
    code: 'MR',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+222',
  },
  {
    name: 'Mauritius',
    code: 'MU',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+230',
  },
  {
    name: 'Mayotte',
    code: 'YT',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+262',
  },
  {
    name: 'Mexico',
    code: 'MX',
    timezone: 'Central Standard Time (Mexico)',
    utc: 'UTC-06:00',
    mobileCode: '+52',
  },
  {
    name: 'Micronesia',
    code: 'FM',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+691',
  },
  {
    name: 'Moldova',
    code: 'MD',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+373',
  },
  {
    name: 'Monaco',
    code: 'MC',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+377',
  },
  {
    name: 'Mongolia',
    code: 'MN',
    timezone: 'Ulaanbaatar Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+976',
  },
  {
    name: 'Montenegro',
    code: 'ME',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+382',
  },
  {
    name: 'Montserrat',
    code: 'MS',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-664',
  },
  {
    name: 'Morocco',
    code: 'MA',
    timezone: 'Morocco Standard Time',
    utc: 'UTC',
    mobileCode: '+212',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+258',
  },
  {
    name: 'Myanmar',
    code: 'MM',
    timezone: 'Myanmar Standard Time',
    utc: 'UTC+06:30',
    mobileCode: '+95',
  },
  {
    name: 'Namibia',
    code: 'NA',
    timezone: 'Namibia Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+264',
  },
  {
    name: 'Nauru',
    code: 'NR',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+674',
  },
  {
    name: 'Nepal',
    code: 'NP',
    timezone: 'Nepal Standard Time',
    utc: 'UTC+05:45',
    mobileCode: '+977',
  },
  {
    name: 'Netherlands',
    code: 'NL',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+31',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+687',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    timezone: 'New Zealand Standard Time',
    utc: 'UTC+12:00',
    mobileCode: '+64',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    timezone: 'Central America Standard Time',
    utc: 'UTC-06:00',
    mobileCode: '+505',
  },
  {
    name: 'Niger',
    code: 'NE',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+227',
  },
  {
    name: 'Nigeria',
    code: 'NG',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+234',
  },
  {
    name: 'Niue',
    code: 'NU',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+683',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+672',
  },
  {
    name: 'North Korea',
    code: 'KP',
    timezone: 'Korea Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+850',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+1-670',
  },
  {
    name: 'Norway',
    code: 'NO',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+47',
  },
  {
    name: 'Oman',
    code: 'OM',
    timezone: 'Arabian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+968',
  },
  {
    name: 'Pakistan',
    code: 'PK',
    timezone: 'Pakistan Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+92',
  },
  {
    name: 'Palau',
    code: 'PW',
    timezone: 'Tokyo Standard Time',
    utc: 'UTC+09:00',
    mobileCode: '+680',
  },
  {
    name: 'Palestinian Authority',
    code: 'PS',
    timezone: 'Egypt Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+970',
  },
  {
    name: 'Panama',
    code: 'PA',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+507',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    timezone: 'West Pacific Standard Time',
    utc: 'UTC+10:00',
    mobileCode: '+675',
  },
  {
    name: 'Paraguay',
    code: 'PY',
    timezone: 'Paraguay Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+595',
  },
  {
    name: 'Peru',
    code: 'PE',
    timezone: 'SA Pacific Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+51',
  },
  {
    name: 'Philippines',
    code: 'PH',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+63',
  },
  {
    name: 'Pitcairn Islands',
    code: 'PN',
    timezone: 'Pacific Standard Time',
    utc: 'UTC-08:00',
    mobileCode: '+870',
  },
  {
    name: 'Poland',
    code: 'PL',
    timezone: 'Central European Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+48',
  },
  {
    name: 'Portugal',
    code: 'PT',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+351',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-787 and 1-939',
  },
  {
    name: 'Qatar',
    code: 'QA',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+974',
  },
  {
    name: 'Reunion',
    code: 'RE',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+262',
  },
  {
    name: 'Romania',
    code: 'RO',
    timezone: 'GTB Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+40',
  },
  {
    name: 'Russia',
    code: 'RU',
    timezone: 'Russian Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+7',
  },
  {
    name: 'Rwanda',
    code: 'RW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+250',
  },
  {
    name: 'Saint Barthélemy',
    code: 'BL',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590',
  },
  {
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    code: 'SH',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+290',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-869',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-758',
  },
  {
    name: 'Saint Martin (French part)',
    code: 'MF',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+590',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    timezone: 'Greenland Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+508',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-784',
  },
  {
    name: 'Samoa',
    code: 'WS',
    timezone: 'Samoa Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+685',
  },
  {
    name: 'San Marino',
    code: 'SM',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+378',
  },
  {
    name: 'São Tomé and Príncipe',
    code: 'ST',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+239',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+966',
  },
  {
    name: 'Senegal',
    code: 'SN',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+221',
  },
  {
    name: 'Serbia',
    code: 'RS',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+381',
  },
  {
    name: 'Seychelles',
    code: 'SC',
    timezone: 'Mauritius Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+248',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+232',
  },
  {
    name: 'Singapore',
    code: 'SG',
    timezone: 'Singapore Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+65',
  },
  {
    name: 'Sint Maarten (Dutch part)',
    code: 'SX',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+599',
  },
  {
    name: 'Slovakia',
    code: 'SK',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+421',
  },
  {
    name: 'Slovenia',
    code: 'SI',
    timezone: 'Central Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+386',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+677',
  },
  {
    name: 'Somalia',
    code: 'SO',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+252',
  },
  {
    name: 'South Africa',
    code: 'ZA',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+27',
  },
  {
    name: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    timezone: 'UTC-02',
    utc: 'UTC-02:00',
    mobileCode: '+',
  },
  {
    name: 'South Sudan',
    code: 'SS',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+211',
  },
  {
    name: 'Spain',
    code: 'ES',
    timezone: 'Romance Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+34',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    timezone: 'Sri Lanka Standard Time',
    utc: 'UTC+05:30',
    mobileCode: '+94',
  },
  {
    name: 'Sudan',
    code: 'SD',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+249',
  },
  {
    name: 'Suriname',
    code: 'SR',
    timezone: 'SA Eastern Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+597',
  },
  {
    name: 'Svalbard',
    code: 'SJ',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+47',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+268',
  },
  {
    name: 'Sweden',
    code: 'SE',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+46',
  },
  {
    name: 'Switzerland',
    code: 'CH',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+41',
  },
  {
    name: 'Syria',
    code: 'SY',
    timezone: 'Syria Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+963',
  },
  {
    name: 'Taiwan',
    code: 'TW',
    timezone: 'Taipei Standard Time',
    utc: 'UTC+08:00',
    mobileCode: '+886',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+992',
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+255',
  },
  {
    name: 'Thailand',
    code: 'TH',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+66',
  },
  {
    name: 'Togo',
    code: 'TG',
    timezone: 'Greenwich Standard Time',
    utc: 'UTC',
    mobileCode: '+228',
  },
  {
    name: 'Tokelau',
    code: 'TK',
    timezone: 'Tonga Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+690',
  },
  {
    name: 'Tonga',
    code: 'TO',
    timezone: 'Tonga Standard Time',
    utc: 'UTC+13:00',
    mobileCode: '+676',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-868',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    timezone: 'W. Central Africa Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+216',
  },
  {
    name: 'Turkey',
    code: 'TR',
    timezone: 'Turkey Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+90',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+993',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    timezone: 'Eastern Standard Time',
    utc: 'UTC-05:00',
    mobileCode: '+1-649',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+688',
  },
  {
    name: 'U.S. Minor Outlying Islands',
    code: 'UM',
    timezone: 'UTC-11',
    utc: 'UTC-11:00',
    mobileCode: '+1',
  },
  {
    name: 'Uganda',
    code: 'UG',
    timezone: 'E. Africa Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+256',
  },
  {
    name: 'Ukraine',
    code: 'UA',
    timezone: 'FLE Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+380',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    timezone: 'Arabian Standard Time',
    utc: 'UTC+04:00',
    mobileCode: '+971',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    timezone: 'GMT Standard Time',
    utc: 'UTC',
    mobileCode: '+44',
  },
  {
    name: 'United States',
    code: 'US',
    timezone: 'Pacific Standard Time',
    utc: 'UTC-08:00',
    mobileCode: '+1',
  },
  {
    name: 'Uruguay',
    code: 'UY',
    timezone: 'Montevideo Standard Time',
    utc: 'UTC-03:00',
    mobileCode: '+598',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    timezone: 'West Asia Standard Time',
    utc: 'UTC+05:00',
    mobileCode: '+998',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    timezone: 'Central Pacific Standard Time',
    utc: 'UTC+11:00',
    mobileCode: '+678',
  },
  {
    name: 'Vatican City',
    code: 'VA',
    timezone: 'W. Europe Standard Time',
    utc: 'UTC+01:00',
    mobileCode: '+379',
  },
  {
    name: 'Venezuela',
    code: 'VE',
    timezone: 'Venezuela Standard Time',
    utc: 'UTC-04:30',
    mobileCode: '+58',
  },
  {
    name: 'Vietnam',
    code: 'VN',
    timezone: 'SE Asia Standard Time',
    utc: 'UTC+07:00',
    mobileCode: '+84',
  },
  {
    name: 'Virgin Islands, U.S.',
    code: 'VI',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-340',
  },
  {
    name: 'Virgin Islands, British',
    code: 'VG',
    timezone: 'SA Western Standard Time',
    utc: 'UTC-04:00',
    mobileCode: '+1-284',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    timezone: 'UTC+12',
    utc: 'UTC+12:00',
    mobileCode: '+681',
  },
  {
    name: 'Yemen',
    code: 'YE',
    timezone: 'Arab Standard Time',
    utc: 'UTC+03:00',
    mobileCode: '+967',
  },
  {
    name: 'Zambia',
    code: 'ZM',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+260',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    timezone: 'South Africa Standard Time',
    utc: 'UTC+02:00',
    mobileCode: '+263',
  },
];

const questionType = {
  DUMMY: 'Dummy',
};

const fraudScoreLevels = {
  LOW: 0,
  SOFT: 1,
  HARD: 2,
};

const reportBuilderType = {
  ACTIVITY: 'activity',
  COHORT: 'cohort',
  MEDIA_SPEND: 'media-spend',
}

const faceTecStatuses = {
  LIVENESS_STARTED: 'liveness-started',
  IDSCAN_STARTED: 'idscan-started',
  DUPES_FOUND: 'dupes-found',
  VERIFIED: 'verified',
};

export default {
  dimensions,
  eventsMeasures,
  measures,
  displayableDimensions,
  displayableMeasures,
  cohortDimensions,
  cohortMeasures,
  displayableCohortDimensions,
  displayableCohortMeasures,
  displayableMediaSpendDimensions,
  displayableMediaSpendMeasures,
  reportBuilderType,
  defaultSortBy,
  sortDirections,
  defaultSortDirection,
  sortDirectionsToMongo,
  veriffEventCodes,
  veriffNegativeDecisionCodes,
  veriffDecisionCodes,
  veriffCodes,
  veriffReasons,
  veriffReasonsDisplayOrder,
  DATE_FORMAT,
  DATETIME_FORMAT,
  templates,
  userTabs,
  userSecurityMarks,
  fraudMarksExceptions,
  mandrilTemplate,
  decisionCodes,
  userMarks,
  fraudUserMarks,
  churnIndications,
  amazonEmailStatuses,
  clickStatus,
  paymentMethodIcons,
  userOSIcons,
  surveysProviders,
  surveysProvidersValues,
  verticals,
  veriffStatusByCodes,
  appNameById,
  orderVerificationErrors,
  setAllMark,
  clickFamousPlatform,
  orderStatus,
  userFamousPlatform,
  translatorRoles,
  projectStatuses,
  questionTypes,
  languagesWithCode,
  surveyFeedbackOptions,
  federatedOrLucidDQReasonsByNumber,
  innovateDQReasonsByText,
  MCDQReasonsByNumber,
  pureSpectrumDQReasonsByNumber,
  SSIDQReasonsByValue,
  SSI2DQReasonsByValue,
  rewardsTypes,
  rewardsCardStatus,
  rewardsUpdatedFields,
  amazonCardsStatuses,
  amazonCardsAmounts,
  defaultCurrencyRate,
  countriesWithCode,
  questionType,
  wincubeCardIcons,
  fraudScoreLevels,
  cardsServices,
  banReasons,
  faceTecStatuses,
};
