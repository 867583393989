/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "~/_metronic/layout";
import BasePage from "./BasePage";
import TranslationsPage from "./TranslationsPage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );
  const user = useSelector(({ auth }) => auth.user);
  const clicksReportIsLoading = useSelector((state) => state.clicksReport.isLoading);
  const usersIsLoading = useSelector(state => state.users.isLoading);
  const bannedUsersIsLoading = useSelector(state => state.bannedUsers.isLoading);
  const userIsLoading = useSelector(state => state.user.isLoading);
  const surveysIsLoading = useSelector((state) => state.surveys.isLoading);
  const apiLogIsLoading = useSelector(state => state.apiLog.isLoading);
  const highReversalRateBansIsLoading = useSelector((state) => state.highReversalRateBans.isLoading);
  const veriffLogIsLoading = useSelector((state) => state.veriffLog.isLoading);
  const featuresIsLoading = useSelector(state => state.features.isLoading);
  const languagesIsLoading = useSelector((state) => state.languages.isLoading);
  const keysIsLoading = useSelector(state => state.keys.isLoading);
  const keysGetParamsIsLoading = useSelector(state => state.keys.getParamsIsLoading);
  const productsIsLoading = useSelector(state => state.products.isLoading);
  const translatorsIsLoading = useSelector(state => state.translators.isLoading);
  const veriffBlocksIsLoading = useSelector((state) => state.veriffBlocks.isLoading);
  const surveyListResultsLogsIsLoading = useSelector(state => state.surveyListResultsLogs.isLoading);
  const surveyUpdatesLogsIsLoading = useSelector(state => state.surveyUpdatesLogs.isLoading);
  const rewardsIsLoading = useSelector((state) => state.rewards.isLoading);
  const rewardProvidersIsLoading = useSelector((state) => state.rewards.rewardProvidersIsLoading);
  const currentRewardIsLoading = useSelector((state) => state.rewards.currentIsLoading);
  const updatingCurrentRewardIsLoading = useSelector(state => state.rewards.updatingIsLoading);
  const fundsHistoryIsLoading = useSelector(state => state.rewards.fundsHistoryIsLoading);
  const amazonInventoryIsLoading = useSelector(state => state.amazonInventory.isLoading);
  const amazonInventoryDataIsLoading = useSelector(state => state.amazonInventory.dataIsLoading);
  const amazonInventoryUploadCardIsLoading = useSelector(state => state.amazonInventory.uploadCardIsLoading);
  const bitcoinRecordsIsLoading = useSelector(state => state.bitcoinRecords.isLoading);
  const bitcoinRecordsDataIsLoading = useSelector(state => state.bitcoinRecords.dataIsLoading);
  const bitcoinBalanceIsLoading = useSelector(state => state.bitcoinRecords.bitcoinBalanceIsLoading);
  const batchesHistoryIsLoading = useSelector(state => state.amazonInventory.batchesHistoryIsLoading);
  const ordersIsLoading = useSelector(state => state.orders.isLoading);
  const atomsManagementIsLoading = useSelector(state => state.atomsManagement.isLoading);
  const isLoading = clicksReportIsLoading || keysIsLoading || usersIsLoading || bannedUsersIsLoading || surveysIsLoading || apiLogIsLoading
    || highReversalRateBansIsLoading || veriffLogIsLoading || featuresIsLoading || languagesIsLoading
    || productsIsLoading || translatorsIsLoading || veriffBlocksIsLoading || keysGetParamsIsLoading
    || surveyListResultsLogsIsLoading || surveyUpdatesLogsIsLoading || productsIsLoading || translatorsIsLoading
    || veriffBlocksIsLoading || keysGetParamsIsLoading || rewardsIsLoading || userIsLoading
    || currentRewardIsLoading || updatingCurrentRewardIsLoading || amazonInventoryIsLoading
    || amazonInventoryDataIsLoading || amazonInventoryUploadCardIsLoading || bitcoinRecordsIsLoading
    || bitcoinRecordsDataIsLoading || bitcoinBalanceIsLoading || batchesHistoryIsLoading || fundsHistoryIsLoading
    || rewardProvidersIsLoading || ordersIsLoading || atomsManagementIsLoading;

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout isLoading={isLoading}>
          {user?.role ?
            (<TranslationsPage />)
            : (<BasePage />)}
        </Layout>
      )}
    </Switch>
  );
}
