import _ from 'lodash';
import React from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Card, CardContent, Box } from '@material-ui/core';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import filterFactory, { customFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { Pagination } from '~/_metronic/_partials/controls';
import { actions } from '~/app/modules/VeriffBlocks/actions';
import { sortCaret } from '~/_metronic/_helpers';
import {
  onTableChangeHelper,
  usePaginationFilteringAndSorting,
  decisionCodeToDisplay,
  useDidMount,
  textFilterOnBlurHelperWithAutoComplete,
} from '~/app/helpers';
import { Link } from "react-router-dom";
import enums from "~/app/helpers/enums";

const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "30", value: 30 },
];

const sortableColumn = {
  sort: true, sortCaret,
};

const verificationStatusOptions = Object.entries(enums.veriffNegativeDecisionCodes).map(([codeStr, codeNum]) => (
  { value: codeNum, label: _.camelCase(codeStr) }
));

const UserEmailLinkCell = ({ user }) => (
  <div>
    <Link
      target="_blank"
      to={`/users/${user._id}`}
    > {user.email} </Link>
  </div>
);

const columns = (filters, useCountryFieldSort) => [{
  ...sortableColumn,
  dataField: 'createdAt', text: 'Time Of Block',
  type: 'date',
  style: { width: '210px', minWidth: '210px', textAlign: 'left' },
}, {
  dataField: 'user._email', text: 'Email',
  formatter: (_cell, row) => <UserEmailLinkCell user={row.user} />,
  style: { width: '250px', textAlign: 'right' },
}, {
  ...sortableColumn,
  dataField: 'user.countryISO', text: 'Country',
  sort: useCountryFieldSort,
  filter: customFilter(),
  filterRenderer:(onFilter, column) => textFilterOnBlurHelperWithAutoComplete(
    onFilter,
    column,
    {style: { width: "50px" }},
    actions,
    'veriffBlocks'
  ),
  style: { width: '110px', minWidth: '110px', textAlign: 'right' },
}, {
  ...sortableColumn,
  type: 'number',
  dataField: 'verification.code', text: 'Status',
  formatter: (_cell, row) => <div>{decisionCodeToDisplay(row.verification.status)}</div>,
  style: { width: '210px', textAlign: 'right' },
  filter: selectFilter({
    defaultValue: filters['verification.code'],
    placeholder: ' ',
    style: { width: '150px' },
    options: verificationStatusOptions,
  }),
}];

const VeriffBlocksReport = () => {
  const dispatch = useDispatch();
  const {
    sortBy, sortDirection,
    pageNumber, pageSize, totalCount,
    filters,
  } = usePaginationFilteringAndSorting(dispatch, 'veriffBlocks', actions);
  const report = useSelector(state => state.veriffBlocks.report, shallowEqual);
  const useCountryFieldSort = useSelector((state) => state.veriffBlocks.useCountryFieldSort);

  const didMount = useDidMount();

  const onTableChange = onTableChangeHelper({
    actions, dispatch,
    pageNumber, pageSize,
    sortBy, sortDirection,
    filters,
    allFilterFields: ['user.countryISO', 'verification.code'],
    numberFilterFields: ['verification.code'],
    didMount,
  });

  const paginationOptions = {
    custom: true,
    totalSize: +totalCount,
    sizePerPageList,
    sizePerPage: +pageSize,
    page: +pageNumber,
  };

  const reportColumns = columns(filters, useCountryFieldSort);

  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <Pagination isLoading={false} paginationProps={paginationProps}>
                  <BootstrapTable
                    remote
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    headerClasses="table table-vertical-top"
                    bootstrap4
                    keyField="_id"
                    columns={reportColumns}
                    data={report}
                    filter={ filterFactory() }
                    defaultSorted={[{
                      dataField: 'createdAt',
                      order: enums.sortDirections.desc,
                    }]}
                    onTableChange={onTableChange}
                    {...paginationTableProps}
                  >
                  </BootstrapTable>
                </Pagination>
              )}
            </PaginationProvider>
        </CardContent>
      </Card>
    </Box>
  );
};

export default VeriffBlocksReport;

