import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Card, CardContent, Button, TextField } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import SVG from 'react-inlinesvg';

import { toAbsoluteUrl } from '~/_metronic/_helpers';
import enums from '~/app/helpers/enums';
import { actions } from '~/app/modules/AtomsManagement/actions';

const LEAD_BONUS_KEY = 'leadBonus';
const DQ_AMOUNT_KEY = 'dqAmount';
const DQ_WALLET_AMOUNT_KEY = 'dqWalletAmount';
const STREAK_BONUS_MOD_7_KEY = 'streakBonusMod7';

const getCountryName = (countryISO) => {
  if (countryISO === 'default') {
    return 'Default';
  }

  const foundItem = enums.countriesWithCode.find((item) => item.code.toUpperCase() === countryISO);
  return foundItem?.name || `? ${countryISO}`;
};

const SettingsFormFields = () => {
  const dispatch = useDispatch();
  const defaultSettings = useSelector(state => state.atomsManagement.atomSettings['default']);
  const currentForm = useSelector(state => state.atomsManagement.currentForm, shallowEqual);

  const handleChange = (field) => (e) => {
    dispatch(actions.changeFormField({ [field]: e.target.value }));
  };

  const isDifferIcon = (key) => {
    if (currentForm?.[key] && currentForm[key] !== defaultSettings[key]) {
      return (
        <span className="svg-icon svg-icon-primary w-30px">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")} />
        </span>
      );
    }
    return <span className="w-30px" />;
  };

  const inputWrapper = (key, label, indentLabel = false) => (
    <div className="d-flex w-700px align-items-center mb-3" key={key}>
      <div className={`w-400px ${indentLabel ? "pl-10" : ""}`}>{label}</div>
      <div className="w-130px d-flex align-items-center space-between">
        {isDifferIcon(key)}
        <TextField
          className="w-100px p-0"
          variant="outlined"
          margin="dense"
          onChange={handleChange(key)}
          value={currentForm?.[key] || ''} />
      </div>
    </div>
  );

  return (
    <>
      {inputWrapper(LEAD_BONUS_KEY, 'Atoms per Success')}
      {inputWrapper(DQ_AMOUNT_KEY, 'Atoms per DQ')}
      <div className="d-flex w-700px align-items-start mb-3">
        <div className="w-400px">Atoms per Streak Day</div>
      </div>
      { [0,1,2,3,4,5,6].map((day) => (
        inputWrapper(`${STREAK_BONUS_MOD_7_KEY}_${day}`, `Day ${day + 1}`, true)
      ))}
      {inputWrapper(DQ_WALLET_AMOUNT_KEY, 'Micropayment Amount (USD)')}
    </>
  );
};

const AtomSettingsForm = () => {
  const dispatch = useDispatch();
  const currentFormKey = useSelector(state => state.atomsManagement.currentFormKey);
  const isDefault = currentFormKey === 'default';

  const handleCancelClick = () => {
    dispatch(actions.setFormKey('default'));
  };

  const handleSetDefault = () => {
    dispatch(actions.setCurrentAsDefault());
  };

  const handleSaveChanges = () => {
    dispatch(actions.saveCurrentAtomSettings());
  };

  return (
    <Card className="mb-5 max-w-1000px mx-auto">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-center pb-3 border-bottom border-light-secondary mb5">
          <h3 className="mb-0">{getCountryName(currentFormKey)} Settings</h3>
          <div className="d-flex">
            { !isDefault && (
              <>
                <Button className="btn btn-light-info mr-3 w-150px" onClick={handleSetDefault}>Set Default</Button>
                <Button className="btn btn-light-primary mr-3 w-150px" onClick={handleCancelClick}>Cancel</Button>
              </>
            )}
            <Button className="btn btn-primary w-150px" onClick={handleSaveChanges}>Save Changes</Button>
          </div>
        </div>
        <div className="d-flex flex-column w-700px mx-auto pt-10">
          <SettingsFormFields />
        </div>
      </CardContent>
    </Card>
  );
};

const CountryFormLink = ({ countryISO }) => {
  const dispatch = useDispatch();
  const allSettings = useSelector(state => state.atomsManagement.atomSettings);

  const openCountryForm = () => {
    dispatch(actions.setFormKey(countryISO));
  };


  const differsFromDefault = () => {
    const defaultSettings = allSettings['default'];
    const countrySettings = allSettings[countryISO];
    for (const settingKey of Object.keys(countrySettings)) {
      if (countrySettings[settingKey] !== defaultSettings[settingKey]) {
        return true;
      }
    };
    return false;
  };

  return (
    <div onClick={openCountryForm} style={{
      cursor: "pointer",
      flexBasis: "30%",
      padding: "20px",
      color: "#3699FF",
    }}>
      {getCountryName(countryISO)}({countryISO})
      { differsFromDefault() && (
        <span className="svg-icon svg-icon-primary ml-2">
          <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")} />
        </span>
      )}
    </div>
  );
};

const CountrySelector = () => {
  const currentFormKey = useSelector(state => state.atomsManagement.currentFormKey);
  const allSettings = useSelector(state => state.atomsManagement.atomSettings);

  if (currentFormKey !== 'default') {
    return null;
  }

  const countries = Object.keys(allSettings).filter((countryISO) => countryISO !== 'default');
  return (
    <Card className="mb-5 max-w-1000px mx-auto">
      <CardContent className="border text-dark border-light-dark rounded">
        <div className="d-flex justify-content-between align-items-center pb-3 border-bottom border-light-secondary mb5">
          <h3 className="mb-0">Settings per Country</h3>
        </div>
        <div className="mt-10 d-flex flex-wrap justify-content-center">
          { countries.map((countryISO) => (
            <CountryFormLink countryISO={countryISO} key={countryISO} />
          ))}
        </div>
      </CardContent>
    </Card>
  );
};

const AtomsManagement = () => {
  const dispatch = useDispatch();
  const currentFormKey = useSelector(state => state.atomsManagement.currentFormKey);
  const inited = useSelector(state => state.atomsManagement.inited);
  const showErrorAlert = useSelector(state => state.atomsManagement.showErrorAlert);
  const showSuccessAlert = useSelector(state => state.atomsManagement.showSuccessAlert);
  const serverError = useSelector(state => state.atomsManagement.serverError);

  useEffect(() => {
    dispatch(actions.fetchAtomSettings());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inited) {
      dispatch(actions.setFormKey('default'));
    }
  }, [inited]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showSuccessAlert) {
      setTimeout(() => dispatch(actions.closeSuccessAlert()), 3000);
    }
  }, [showSuccessAlert]); // eslint-disable-line react-hooks/exhaustive-deps

  let breadcrumb = '';
  if (currentFormKey !== 'default') {
    breadcrumb = `${getCountryName(currentFormKey)} (${currentFormKey})`;
  }

  return (
    <>
      <Card className="mb-3">
        <CardContent className="border text-dark border-light-dark rounded pt-6 pb-6">
          <div className="d-flex align-items-center">
            <h3 className="mb-0" >Atoms Management</h3>
            <div className="ml-3 text-muted">{breadcrumb}</div>
          </div>
        </CardContent>
      </Card>
      { inited && (
        <>
          <AtomSettingsForm />
          <CountrySelector />
        </>
      )}
      {showErrorAlert && (
        <div style={{ position: "fixed", top: '30%', zIndex: '1000', left: '45%' }}>
          <Alert onClose={() => dispatch(actions.closeErrorAlert())} severity="error" variant="filled">
            <AlertTitle>Something Went Wrong</AlertTitle>
            {serverError}
          </Alert>
        </div>
      )}
      {showSuccessAlert && (
        <div style={{ position: "fixed", top: '30%', zIndex: '1000', left: '45%' }}>
          <Alert onClose={() => dispatch(actions.closeSuccessAlert())} severity="success" variant="filled">
            <AlertTitle>Saved Successfully!</AlertTitle>
          </Alert>
        </div>
      )}
    </>
  );
};

export default AtomsManagement;

